
import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import VK, { Group } from 'react-vk';

const VkWidget = () => {
    return (


       
            <VK>
            <Group groupId={96200034} options={{mode: 4, width: "auto", height: "400"}} />
            </VK>
       
    );
}

export default VkWidget;

