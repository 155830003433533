import React from 'react';

import BookItems from '../../components/BooksGrid/BookItems';
import BookSidebar from '../../components/Sidebar/BookSidebar';

import SideBarList from '../../components/Sidebar/SideBarList';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ContentLoader from 'react-content-loader';
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';


class LibGenres extends React.Component {

    constructor(props) {
        super(props);
        this.state = { GenreBooks: [], Meta: [], loading: true, nexturl: false, pervurl: false };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.genreBooksData();


    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.genreBooksData();

            window.scrollTo(0, 0);
        }
    }



    static renderGenreBooks(Meta, GenreBooks, nexturl, pervurl) {
        return (




            <div className="main-content  category-page">


                <h1 className="inline-title"> </h1>




                <div className="content-block">
                    <div className="block-wrap">
                        <div className="block">
                            <div className="tab-wrap module type-book grid">
                                <div className="tab search">




                                    {GenreBooks.results.length ? (

                                        <>
                                            {GenreBooks.results.map((results, idx) =>

                                                <BookItems key={idx} id={results.id} owl="none" results={results} />


                                            )
                                            }
                                        </>
                                    )
                                        :
                                        (<div className="no-book"><p>Извините. На данный момент нет  доступных книг в этой категории</p></div>)
                                    }


                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="foot-block">
                    <div className="text-center">


                        {pervurl ? (

                            <Link to={GenreBooks.previousUrl}> <div className="btnP btn">Назад </div></Link>

                        ) : (
                            <div>

                            </div>
                        )
                        }



                        {nexturl ? (

                            <Link to={GenreBooks.nextUrl}> <div className="btnN btn">Далее </div></Link>

                        ) : (
                            <div>

                            </div>
                        )
                        }





                    </div>
                </div>





            </div>



        )
    };





    render() {
        let contents = this.state.loading
            ?




            <div className="main-content  category-page">


                <h1 className="inline-title"> </h1>

                <div className="content-block">
                    <div className="block-wrap">
                        <div className="block">
                            <div className="tab-wrap module type-book grid">
                                <div className="tab search">

                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />
                                    <BookItemLoader />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>




            : LibGenres.renderGenreBooks(this.state.Meta, this.state.GenreBooks, this.state.nexturl, this.state.pervurl);

        return (
            <div>

                <p></p>
                {contents}
            </div>
        );
    }


    async genreBooksData() {

        const id = this.props.match.params.id;

        


        const search = this.props.location.search; // 
        const params = new URLSearchParams(search);
        let pg = params.get('PageNumber'); // 


        if (pg == null) {
            pg = 1;
        }




        const response = await fetch('/api/GenreBooks?id=' + id + '&PageNumber=' + pg +'&BookType=3');

        const meta = JSON.parse(response.headers.get('x-pagination'));

        const data = await response.json();
        this.setState({ GenreBooks: data, Meta: meta, loading: false, nexturl: meta.HasNext, pervurl: meta.HasPrevious });


    }

}

export default LibGenres;













