
import React, { Component, useState, useEffect } from 'react';
import './help.css';
import AccountSideBar from '../../components/Sidebar/AccountSideBar';
import { useForm } from "react-hook-form";
import OkStatus from "../../components/IconStatus/OkStatus";

class Collapsible extends React.Component {
    constructor(props) {
        super(props)

        // Bind class methods
        this.handleTriggerClick = this.handleTriggerClick.bind(this);
        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
        this.continueOpenCollapsible = this.continueOpenCollapsible.bind(this);

        // Defaults the dropdown to be closed
        if (props.open) {
            this.state = {
                isClosed: false,
                shouldSwitchAutoOnNextCycle: false,
                height: 'auto',
                transition: 'none',
                hasBeenOpened: true,
                overflow: props.overflowWhenOpen,
                inTransition: false,
            }
        } else {
            this.state = {
                isClosed: true,
                shouldSwitchAutoOnNextCycle: false,
                height: 0,
                transition: `height ${props.transitionTime}ms ${props.easing}`,
                hasBeenOpened: false,
                overflow: 'hidden',
                inTransition: false,
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shouldOpenOnNextCycle) {
            this.continueOpenCollapsible();
        }

        if (prevState.height === 'auto' && this.state.shouldSwitchAutoOnNextCycle === true) {
            window.setTimeout(() => { // Set small timeout to ensure a true re-render
                this.setState({
                    height: 0,
                    overflow: 'hidden',
                    isClosed: true,
                    shouldSwitchAutoOnNextCycle: false,
                });
            }, 50);
        }

        // If there has been a change in the open prop (controlled by accordion)
        if (prevProps.open !== this.props.open) {
            if (this.props.open === true) {
                this.openCollapsible();
                this.props.onOpening();
            } else {
                this.closeCollapsible();
                this.props.onClosing();
            }
        }
    }

    closeCollapsible() {
        this.setState({
            shouldSwitchAutoOnNextCycle: true,
            height: this.refs.inner.offsetHeight,
            transition: `height ${this.props.transitionCloseTime ?
                this.props.transitionCloseTime : this.props.transitionTime}ms ${this.props.easing}`,
            inTransition: true,
        });
    }

    openCollapsible() {
        this.setState({
            inTransition: true,
            shouldOpenOnNextCycle: true,
        });
    }

    continueOpenCollapsible() {
        this.setState({
            height: this.refs.inner.offsetHeight,
            transition: `height ${this.props.transitionTime}ms ${this.props.easing}`,
            isClosed: false,
            hasBeenOpened: true,
            inTransition: true,
            shouldOpenOnNextCycle: false,
        });
    }

    handleTriggerClick(event) {
        event.preventDefault();

        if (this.props.triggerDisabled) {
            return
        }

        if (this.props.handleTriggerClick) {
            this.props.handleTriggerClick(this.props.accordionPosition);
        } else {
            if (this.state.isClosed === true) {
                this.openCollapsible();
                this.props.onOpening();
            } else {
                this.closeCollapsible();
                this.props.onClosing();
            }
        }
    }

    renderNonClickableTriggerElement() {
        if (this.props.triggerSibling && typeof this.props.triggerSibling === 'string') {
            return (
                <span className={`${this.props.classParentString}__trigger-sibling`}>{this.props.triggerSibling}</span>
            )
        } else if (this.props.triggerSibling) {
            return <this.props.triggerSibling />
        }

        return null;
    }

    handleTransitionEnd() {
        // Switch to height auto to make the container responsive
        if (!this.state.isClosed) {
            this.setState({ height: 'auto', overflow: this.props.overflowWhenOpen, inTransition: false });
            this.props.onOpen();
        } else {
            this.setState({ inTransition: false });
            this.props.onClose();
        }
    }

    render() {
        var dropdownStyle = {
            height: this.state.height,
            WebkitTransition: this.state.transition,
            msTransition: this.state.transition,
            transition: this.state.transition,
            overflow: this.state.overflow,
        }

        var openClass = this.state.isClosed ? 'is-closed' : 'is-open';
        var disabledClass = this.props.triggerDisabled ? 'is-disabled' : '';

        //If user wants different text when tray is open
        var trigger = (this.state.isClosed === false) && (this.props.triggerWhenOpen !== undefined)
            ? this.props.triggerWhenOpen
            : this.props.trigger;

        // If user wants a trigger wrapping element different than 'span'
        const TriggerElement = this.props.triggerTagName;

        // Don't render children until the first opening of the Collapsible if lazy rendering is enabled
        var children = this.props.lazyRender
            && !this.state.hasBeenOpened
            && this.state.isClosed
            && !this.state.inTransition ? null : this.props.children;

        // Construct CSS classes strings
        const triggerClassString = `${this.props.classParentString}__trigger ${openClass} ${disabledClass} ${
            this.state.isClosed ? this.props.triggerClassName : this.props.triggerOpenedClassName
            }`;
        const parentClassString = `${this.props.classParentString} ${
            this.state.isClosed ? this.props.className : this.props.openedClassName
            }`;
        const outerClassString = `${this.props.classParentString}__contentOuter ${this.props.contentOuterClassName}`;
        const innerClassString = `${this.props.classParentString}__contentInner ${this.props.contentInnerClassName}`;

        return (
            <div className={parentClassString.trim()}>
                <TriggerElement
                    className={triggerClassString.trim()}
                    onClick={this.handleTriggerClick}
                    style={this.props.triggerStyle && this.props.triggerStyle}
                    onKeyPress={(event) => {
                        const { key } = event;
                        if (key === ' ' || key === 'Enter') {
                            this.handleTriggerClick(event);
                        }
                    }}
                    tabIndex={this.props.tabIndex && this.props.tabIndex}
                >
                    {trigger}
                </TriggerElement>

                {this.renderNonClickableTriggerElement()}

                <div
                    className={outerClassString.trim()}
                    ref="outer"
                    style={dropdownStyle}
                    onTransitionEnd={this.handleTransitionEnd}
                >
                    <div
                        className={innerClassString.trim()}
                        ref="inner"
                    >
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

Collapsible.defaultProps = {
    transitionTime: 200,
    transitionCloseTime: null,
    triggerTagName: 'span',
    easing: 'linear',
    open: false,
    classParentString: 'Collapsible',
    triggerDisabled: false,
    lazyRender: false,
    overflowWhenOpen: 'hidden',
    openedClassName: '',
    triggerStyle: null,
    triggerClassName: '',
    triggerOpenedClassName: '',
    contentOuterClassName: '',
    contentInnerClassName: '',
    className: '',
    triggerSibling: null,
    onOpen: () => { },
    onClose: () => { },
    onOpening: () => { },
    onClosing: () => { },
    tabIndex: null,
};


export default function Support () {


    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const onSubmit = (data) => {

        SendMessage(data);
            
    };


    useEffect(() => {

        document.title = `Служба тех поддержки`;

        window.scrollTo(0, 0);
        scrollTop();
    })


    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    async function SendMessage(data) {


        
        const headersReq = new Headers();
        headersReq.append('Content-Type', 'application/json');
        const res = await
            fetch('/api/SendHelpDeskMessage', {
                method: 'post',            
                headers: headersReq,
                body: JSON.stringify(data)
            });

        let d = await res.json()

        if (d != "error") {
            
            setViewForm("loadDisp");
            setViewOk("loadView");
            
        }
        else {
         
         
        }



    }

    const [viewForm, setViewForm] = useState('loadView');
    const [viewOk, setViewOk] = useState('loadDisp');


   

        return (

           

            
                <div className="main-content ">
                    <div className="content help-page">
                        
                        <h1>                            Вопросы-Ответы</h1>

                            
                        <Collapsible trigger={'Как я могу связаться с вами?'}>
                            <p>Лучший способ связаться с нами - заполнить онлайн-форму. Мы стараемся ответить на все вопросы в течение 24 часов.</p>
                        </Collapsible>


                        <Collapsible trigger={'Какие варианты оплаты доступны для меня?'}>
                        <p>Мы принимаем платежи по следующим банковским картам:<b> Visa, Mastercard, Maestro, Мир, JCB, Diners Club, American Express</b>. Покупатель может заплатить максимум 250 000 ₽ за один раз, в месяц — 500 000 ₽ одной банковской картой.<br/>
                        Электронные деньги: <b>ЮMoney, WebMoney</b>
                        </p>    
                        </Collapsible>

                    <Collapsible trigger={'В какие страны Вы осуществляете доставку?'}>
                        <p>Абхазия, Австрия, Азербайджан, Албания, Андорра, Армения, Бангладеш, Бахрейн, Беларусь, Бельгия, Болгария, Босния и Герцеговина, Бруней-Даруссалам, Бутан, Ватикан, Великобритания (Соединенное Королевство), Венгрия, Вьетнам, Германия, Греция, Грузия, Дания, Израиль, Индия, Индонезия, Иордания, Ирландия, Исландия, Испания, Италия, Казахстан, Камбоджа, Катар, Кипр, Киргизия, Китай, Корея (Республика Корея), Кувейт, Лаос, Латвия, Ливан, Литва, Лихтенштейн, Люксембург, Малайзия, Мальдивы, Мальта, Молдова, Монако, Монголия, Мьянма, Непал, Нидерланды, Норвегия, Объединенные Арабские Эмираты, Оман, Польша, Португалия, Македония, Россия, Румыния, Саудовская Аравия, Сербия, Сингапур, Синт-Мартен, Словакия, Словения, Таджикистан, Таиланд, Тайвань, Тимор-Лесте, Туркменистан, Турция, Узбекистан, Украина, Филиппины, Финляндия, Франция, Хорватия, Черногория, Чехия, Швейцария, Швеция, Шри-Ланка, Эстония, Япония</p>
                    </Collapsible>


                    <Collapsible trigger={'С какими сервисами доставки вы работате?'}>
                        <p>На данный момент мы работаем с сервисами <b>СДЭК, ПОЧТА РОССИИ</b></p>
                    </Collapsible>

                    <Collapsible trigger={'Как я могу отследить заказ?'}>
                        <p>Заказ можно отследить <b>по трек номеру,</b> который мы высылаем Вам на личную почту, а так же в разделе детали заказа.</p>
                    </Collapsible>
                    <Collapsible trigger={'Почему у меня обрабатывается долго заказ?'}>
                        <p>Просим учесть, что отправление заказа осуществляется <b>только в рабочие дни</b>. В выходные, праздничные дни <u>отрпавка </u> не осуществляется. После  <u>отравки заказ </u>.а, Вы получите уведомление по почте.</p>
                    </Collapsible>

                    <Collapsible trigger={'Мне не пришло письмо с трек-номером!'}>
                        <p>Пожалуйста,  <b>проверьте правильность</b> email адреса с которого вы зарегистрировались. В случае ошибки, напишите нам обращение через форму. <b>В том числе</b> просим Вас проверить папку СПАМ.</p>
                    </Collapsible>

                    <Collapsible trigger={'Я не могу оформить заказ!'}>
                        <p> При оформления заказа по России, пожалуйста, выберите адрес из выпадающего списка. Если у Вас и дальше возникают трудности, напишите нам через онлайн-форму. </p>
                    </Collapsible>


                </div>

                <div className="help">

                    <div className={viewForm}>
                <div className="aero-form-container">

                    


                            <form onSubmit={handleSubmit(onSubmit)} className="aero-form transparent form-shadow">
                        {/* register your input into the hook by invoking the "register" function */}


                                <div className="aero-form-header">
                                    <h2>
                               Обращение
                               </h2>

                        </div>



                        <div className="col-xs-12">
                            <div className="form-group">
                                <label>
                                   

                                            <input type="text" name="name" placeholder="Введите Ваше имя" required ref={register({ required: true, maxLength: 150 })} />
                                </label>
                            </div>
                        </div>
                          <div className="col-xs-12">
                            <div className="form-group">
                                <label className="control-label" >
                                            <input type="text" name="email" placeholder="Введите Ваш email" required ref={register({ required: true, maxLength: 50  })}/>
                                    
                                    {errors.exampleRequired && <span>Обязательное поле</span>}
                                             </label>
                    
                               
                            </div>
                        </div>

                        <div className="col-xs-12">
                            <div className="form-group">
                                <label className="control-label" >
                                            <input type="text" name="phone" placeholder="Введите Ваш телефон" required ref={register({ required: true, maxLength: 20 })}/>

                                    {errors.exampleRequired && <span>Обязательное поле</span>}
                                </label>


                            </div>
                        </div>


                        <div className="col-xs-12">
                            <div className="form-group">
                                <label className="control-label" >
                                            <textarea type="text" name="message" placeholder="Текст обращения" required ref={register({ required: true, maxLength: 1000 })}/>

                                    {errors.exampleRequired && <span>Обязательное поле</span>}
                                </label>


                            </div>
                        </div>

                        <div className="aero-form-submit">
                                    <button className="tap blue full-width" type="submit">Отправить</button>
                        </div>
                    </form>
                </div>


                    </div>

                    <div className={viewOk }>
                    <div className="OkCheck">
                        <OkStatus />
                        <p>Сообщение успешно отпарвлено</p>
                      
                        </div>
                    </div>

                </div>
            </div>
           
      
            
        );
    }




