import React, { Component } from 'react';
import HeadLayout from '../../components/HeadLayout/HeadLayout';
import FooterWrap from '../../components/Footer/FooterWrap';
import MobileNavBtn from '../../components/HeadLayout/MobileNavBtn';
import './Layout.css';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import SideBarList from '../../components/Sidebar/SideBarList';

export class LayoutBase extends Component {
    static displayName = LayoutBase.name;

    render() {
        return (
            <div>
           

                <div className="content-wrap">
                    <div className="sidebar">
                        <SideBarList />
                    </div>

                    {this.props.children}

                </div>
       
            </div>
        );
    }
}
