import React, { Component, useContext, useState, useEffect } from 'react';


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import authService from '../../components/api-authorization/AuthorizeService';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';


import ReactJkMusicPlayer from 'react-jinke-music-player'

const Track = (props) => {

    const [track, setTrack] = useState();
    const [loadStatus, setLoadStatus] = useState("not")

    useEffect(() => {


        if (loadStatus == "not") {
            getTrack()

            setLoadStatus("yes")
        }


    });

    async function getTrack() {
        const url = window.location.pathname;
   
        const id = url.substring(url.lastIndexOf('/') + 1);
     
        const token = await authService.getAccessToken();
        const response = await fetch('/api/GetTrack?id=' + id);

        const data = await response.json();
        setTrack(data);
        


    };






    return (
        <div className="main-content  category-page">

            <div className="block-wrap">





                <div className="content-block">
                    <div className="block-wrap  paddin-zero">
                        <div className="block">

                        

                            <div className="tab-wrap module type-book grid">
                                <div className="tab search">
                                  <div className="ordertable">
                {track != null &&
                    <>
                    <table className="table">
                

                        <tbody>
                            <tr className="trackView">
                                                        <td><img src={track.coverImg} /></td>
                                                        <td className="item-info"><h1>{track.titleRus}</h1></td>
                      
                                <td><Link to={`/books/`+track.bookId} className="btn">Перейти к книге</Link></td>
                        </tr>

                    


                      
                       


                        </tbody>
                    </table>
                    <ReactJkMusicPlayer glassBg={true} audioLists={track.audioList} remove={false} autoPlay={false} preload={true} mode="full" theme="light" /> 
                    </>
                }


                {track == "" &&
                    <p>трек не найден</p>
                }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>



    )
}

export default Track;