import React, { useState, useContext } from 'react'
import { slide as Menu } from 'react-burger-menu'

// make a new context
export const BurgerMenuContext = React.createContext();

// create the provider
 const BurgerMenuContextProvider= (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false)

    return (
        <BurgerMenuContext.Provider value={{
            isMenuOpen: menuOpenState,
            toggleMenu: () => setMenuOpenState(!menuOpenState),
            stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
        }}>
            {props.children}
        </BurgerMenuContext.Provider>
    )
}

export default BurgerMenuContextProvider;