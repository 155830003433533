import React, { Component, useContext, useState, useEffect } from 'react';


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import authService from '../../components/api-authorization/AuthorizeService';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';



const OrderDetails = (props) => {

    const [orders, setOrders] = useState("null")
    const [loadStatus, setLoadStatus] = useState("not")

    useEffect(() => {


        if (loadStatus == "not") {
            getMyOrders(props)
            
            setLoadStatus("yes")
        }


    });

    async function getMyOrders(props) {
        const token = await authService.getAccessToken();
        const id = props.match.params.id; // 
        
        const url = '/api/GetOrderInfo/' + id;
        
        const headersReq = new Headers();

        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);


        const response = await fetch(url, {
            headers:  headersReq
        });
        const data = await response.json()
        
        setOrders(data)


    };

    if (orders == "null") {

        return (
            <div className="container">
                <div className="orderinfo">
                    ...
                </div>
            </div>
            
            )

    }


    return (
        <div className="container">
            <div className="orderinfo">
                <h3>Детали заказа №{orders.id} от {orders.dateOrder}</h3>
                <p><span>Ф.И.О : </span>{orders.fullName}</p>
                
             
                {orders.trackCode ?
                    (
                        <p><span>Трек номер : </span>{orders.trackCode}</p>)
                    :(<span/>)
                }
                <p><span>Адрес : </span>{orders.address}</p>
                <p><span>Статус заказа : </span>{orders.orderStatus.nameRus}</p>
                <p><span>Тип оплаты : </span>{orders.paymentType.nameRus}</p>
                <p><span>Доставка : </span>{orders.shipppingService.nameRus} {orders.otherType}</p>
                
                <p><span>Телефон : </span>{orders.phone}</p>
                <p><span>Цена : </span>{orders.amount}</p>
                <p><span>Цена доставки: </span>{orders.deliveryPrice}</p>
            
                <br/>
           
                <table className="table">
                    <thead className="thead">
                        <tr>

                            <th scope="col">Название на татарском</th>
                            <th scope="col">Название на русском</th>
                            <th scope="col">Количество</th>
                            <th scope="col">Цена</th>
                            <th scope="col">#</th>
                            
                        </tr>
                    </thead>

                    <tbody>
                    {orders.bookList.map(item =>


                            <tr key={item.id}>

                            <td>{item.nameRus}</td>

                            <td>{item.nameTat}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                         
                            <td><Nav.Link as={Link} className="btn" to={`/books/${item.bookId}`} >Просмотр </Nav.Link> </td>
                                
                            </tr>
                        )}

                    </tbody>
                </table>


            
            </div>


        </div>



    )
}

export default OrderDetails;