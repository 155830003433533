import React, { Component, useContext, useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel-loop';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



const StoreCatList = () => {


  
        const [Load, setLoad] = useState(false);
        const [Data, setData] = useState();


        useEffect(() => {
            if (Load ===false) {

                LoadSatus();
               
            }
        })


        async function LoadSatus() {

            
            const url = '/api/genresList?id=1';
            const response = await fetch(url);
            const data = await response.json();            
            setData(data);
            console.log(data);
            setLoad(true);


        }


    return (
        <div className="row headcat">
            
            {Data &&

                (Data.map((res, idx) =>

                    <div key={idx} className="col-6 col-sm-4 col-md-4 col-lg-3 genreListCat" >
                        <Link to={`/store/genres/${res.genresViewId}`}>
                            <div className="genreList">
                                {res.nameRus}
                            </div>
                        </Link>
                </div>
                ))
         
            }
        </div>

        );
}

export default StoreCatList;