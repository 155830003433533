import React, { Component } from "react";
import {
    EpubView, // Underlaying epub-canvas (wrapper for epub.js iframe)
    EpubViewStyle, // Styles for EpubView, you can pass it to the instance as a style prop for customize it
    ReactReader, // A simple epub-reader with left/right button and chapter navigation
    ReactReaderStyle // Styles for the epub-reader it you need to customize it
  } from "react-reader";
 
class BookReader extends Component {
  render() {
    return (
        /* The ReactReader will expand to 100% of width/height, so be sure to set a height on the parent element, either with position it absolute of window, set height or use paddingTop for proporsjonal scaling */
        <div className="readerViewer">
        <div style={{ position: "relative", height: "600px", width: "100%" }}>
    <ReactReader
            url={
              "/convert/epub/Suleymanova.epub"
            }
            locationChanged={this.onLocationChanged}
            title={"-"}
            
            getRendition={this.getRendition}
          />
            </div>
        </div>
    );
  }
}
export default BookReader;