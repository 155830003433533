import React, { useEffect, useState } from 'react';


function ErrStatus() {

    return (
        <div className="swal2-icon swal2-error swal2-animate-error-icon">
            <span className="swal2-x-mark">
                <span className="swal2-x-mark-line-left"></span>
                <span className="swal2-x-mark-line-right"></span>
            </span>
        </div>
    )
}

export default ErrStatus;