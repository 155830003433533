import React, { useEffect, useState } from 'react';
import StoreChild from '../../components/BooksGrid/StoreChild';
import StorePub from '../../components/BooksGrid/StorePub';
import StoreTatLesson from '../../components/BooksGrid/StoreTatLesson';

import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';

import StoreCatList from '../../components/Category/StoreCatList';
import { Helmet } from "react-helmet";
import OkStatus from "../../components/IconStatus/OkStatus";
import ErrStatus from "../../components/IconStatus/ErrStatus";
import { Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Loader from 'react-loader-spinner';

function OrderPayPdf(props) {


    const id = props.match.params.id; 
    const url = '/api/pd4pdf/' + id;

    return (

        <div className="container-fluid pay">

            <Helmet>
                <title>Таткнига - Платеж</title>
                <meta name="description" content="Успешно  " />
            </Helmet>

            <div className="mainbox">



                <div className="OkCheck">
                    <OkStatus />
                    <p>Заказ в обработке</p>
                    <p><a href={url} target="_blank"  className="btn" > Скачать счет в PDF </a> </p>
                    <p><Nav.Link as={Link} to="/myorders" className="btn" > Мои заказы </Nav.Link> </p>
                </div>





            </div>
        </div>



    )

}

export default OrderPayPdf;