import React from 'react';
import Brcrumb from '../../components/Book/Brcrumb';
import BookItemBlock from '../../components/Book/BookItemBlock';
import BookDetails from '../../components/Book/BookDetails';
import PropTypes from 'prop-types';

import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import LastBook from '../../components/BooksGrid/LastBook';
import ContentLoader from 'react-content-loader';
import { Helmet } from "react-helmet";




class Book extends React.Component {

    constructor(props) {
        super(props);
        this.state = { books: [], loading: true };
       

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.bookData();
        
      
    }
    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.bookData();

            window.scrollTo(0, 0);
        }
    }





  

    static renderBook(books) {
        return (

           
            <div className="content-wrap paddin-zero" >
                <Helmet>
                    <title>Татарское книжное издательство - {books.bookViewModel.nameTat}    </title>
                    <meta name="description" content={`${books.bookViewModel.nameTat} - ${books.bookViewModel.nameRus}`} />
                </Helmet>

                <div className="main-content no-borders book-page full-width">
                    <Brcrumb genreslist={books} />

                    <div className="item-wrap">
                    

                            <div className="detail-page-carousel-wrap">
                                <div className="block-wrap isbn-recommendation-carousel">
                            
                                <div className="block"></div>

                                <BookItemBlock books={books.bookViewModel} authors={books} isPartner={books.bookViewModel.isPartner} />
                                <BookDetails details={books.bookViewModel} />
                                    {books.audioList ?  (
                                    <div>
                                        <ReactJkMusicPlayer glassBg={true} audioLists={books.audioList} remove={false} autoPlay={false} preload={true} mode="full"    theme="light"/> 
                                          
                                            </div>
                                        ) : (
                                                <div>
                                                </div>
                                            )
                                    }

                                </div>
                            </div>
                    </div>

                    <div className="block-wrap content-block paddin-zero ">
                        <div className="block-header">
                            <h2 className="titleHead">Новинки</h2>
                        </div>
                        <div className="block ">
                            <LastBook />

                        </div>
                    </div>

                </div>
            </div>
        )
    };
       
  


    render() {
        let contents = this.state.loading
            ? <div className="content-wrap  paddin-zero" >
                <div className="main-content content-block no-borders book-page full-width">

                    <div className="item-wrap">


                        <div className="detail-page-carousel-wrap">
                            <div className="block-wrap isbn-recommendation-carousel">

                                <div className="block"></div>


                                <ContentLoader viewBox="0 0 800 400" height={400} width={800} >
                                    <circle cx="472" cy="159" r="7" />
                                    <rect x="487" y="154" rx="5" ry="5" width="220" height="10" />
                                    <circle cx="472" cy="190" r="7" />
                                    <rect x="487" y="184" rx="5" ry="5" width="220" height="10" />
                                    <circle cx="472" cy="219" r="7" />
                                    <rect x="487" y="214" rx="5" ry="5" width="220" height="10" />
                                    <circle cx="472" cy="249" r="7" />
                                    <rect x="487" y="244" rx="5" ry="5" width="220" height="10" />
                                    <rect x="64" y="18" rx="0" ry="0" width="346" height="300" />
                                    <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
                                    <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
                                    <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
                                    <rect x="10" y="20" rx="0" ry="0" width="40" height="44" />
                                    <rect x="10" y="80" rx="0" ry="0" width="40" height="44" />
                                    <rect x="10" y="140" rx="0" ry="0" width="40" height="44" />
                                    <rect x="194" y="329" rx="0" ry="0" width="0" height="0" />
                                    <rect x="192" y="323" rx="0" ry="0" width="0" height="0" />
                                    <rect x="185" y="323" rx="0" ry="0" width="0" height="0" />
                                    <rect x="10" y="200" rx="0" ry="0" width="40" height="44" />
                                    <rect x="470" y="18" rx="0" ry="0" width="300" height="25" />
                                    <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
                                    <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
                                    <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
                                    <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
                                    <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
                                    <rect x="470" y="99" rx="0" ry="0" width="70" height="30" />
                                    <rect x="560" y="99" rx="0" ry="0" width="70" height="30" />
                                </ContentLoader>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            : Book.renderBook(this.state.books);
        
        return (
            <div>
                
                <p></p>
                {contents}
            </div>
        );
    }

    async bookData() {
        const id = this.props.match.params.id;
        
        const response = await fetch('/api/books/' + id);
        const data = await response.json();

        
        this.setState({ books: data, loading: false });
        
        
    }

}
export default Book;


