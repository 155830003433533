import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import './BookItem.css';

import { CartContext } from '../../contexts/CartContext';
import { Button } from 'react-yandex-maps';

function BookItem(props) {

    const { addProduct, cartItems, increase } = useContext(CartContext);
    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }
    
        return (

            <div className={`book-item ${props.owl}`}>
                <div className="item-img">
                    <Link to={props.results.url}>
                        <div className="inner-book">
                            <div className="img startpage" >
                                {props.results.isPartner === true &&

                                    <div className="bookTypePartner">
                                        Партнёр
                            </div>
                                }

                                {props.results.bookType == 0 &&
                                    <div className="bookType">
                                        Печатная
                            </div>
                                }

                                {props.results.bookType == 1 &&
                                    <div className="bookType">
                                        Электронная
                            </div>
                                }
                                {props.results.bookType == 2 &&
                                    <div className="bookType">
                                        Аудио
                            </div>
                                }
                                {props.results.bookType == 5 &&
                                    <div className="bookType">
                                        Трек
                            </div>
                                }
                                {props.results.bookType == 10 &&
                                    <div className="bookType">
                                        Автор
                            </div>

                                }

                                {props.results.sales === 1 &&

                                    <div className="bookTypeSales">
                                        Акция
                            </div>
                                }

                               

                                <img className="lazy loaded" src={props.results.coverImg}    alt={props.results.coverImg} />
                            </div>
                            <div className="page"></div>
                            <div className="page page-2"></div>
                            <div className="page page-3"></div>
                            <div className="img final-page finalpage">
                                <img src={props.results.coverImg} />
                            </div>
                        </div>

                 
                    </Link>
                    
                </div>
                
                <div className="item-info">
                    <div className="item-text">
                    <h3 className="title">
                        <Link to={props.results.url}>{props.results.nameRus} </Link>
                        </h3>
                    </div>
                    
                    
                    <div className="price-wrap">


                  
                           
                                <p className="price">
                            {props.results.price > 0 &&
                                
                            <span>
                                {props.results.price}₽
                                </span>
                        
                            }

                            {props.results.sales === 1 &&
                                <span className="oldPrice">
                                    {props.results.oldPrice}₽
                               </span>

                            }

                            {props.results.price == 0 &&
                                <span>
                                    Бесплатно ₽
                                </span>

                            }


                        </p>

                  

                      

                    </div>
                </div>

                <div className="item-actions">
                    <div className="btn-wrap">
                   




                        {
                            isInCart(props.results) && props.results.price > 0 && props.results.totalQuantity > 0 &&
                            <Link to="/cart"

                                className="btn btn-sm add-to-basket"><i className="bi bi-cart-check bi-inline"></i>Добавлено</Link>
                        }


                        {
                            !isInCart(props.results) && props.results.price > 0 && props.results.totalQuantity > 0 &&
                            <button
                                onClick={() => addProduct(props.results)}
                                        className="btn btn-sm add-to-basket btn-add-size"><i className="bi bi-cart-plus bi-inline"></i>в корзину</button>
                        }




                        {props.results.price == 0 && props.results.bookType != 2 && props.results.bookType != 5&&
                            <span>
                            <Link
                                rel="nofollow"
                                to={props.results.url}
                                className="btn btn-sm  add-to-basket" >
                                <i className="bi bi-file-text  bi-inline"></i> Читать</Link>
                                </span>

                        }
                        {props.results.price == 0 && props.results.bookType == 2 && 
                            <span>
                                <Link
                                    rel="nofollow"
                                    to={props.results.url}
                                    className="btn btn-sm add-to-basket" >
                                <i className="bi bi bi-collection-play bi-inline"></i>Слушать</Link>
                            </span>

                        }
                        {props.results.price == 0 && props.results.bookType == 5 &&
                            <span>
                                <Link
                                    rel="nofollow"
                                    to={props.results.url}
                                    className="btn btn-sm add-to-basket" >
                                <i className="bi bi bi-collection-play bi-inline"></i>Слушать</Link>
                            </span>

                        }


                                
                        
                    </div>
                </div>
            </div>

        );

    }


export default BookItem;
