import React from 'react';
import PropTypes from 'prop-types';

class BookDetails extends React.Component {
    render() {

        return (

            <>
                <div className="item-description">
                    <h2>Описание</h2>
                    <div className="item-excerpt trunc" dangerouslySetInnerHTML={{ __html: this.props.details.descriptionRus }}>

        
                    </div>
                </div>
                <div className="biblio-wrap">
                <div className="biblio-info-wrap">
                    <h2 className="biblio-title">
                        Дополнительная информация</h2>
                    <ul className="biblio-info">

                        <li>
                            <label>Обложка</label>
                            <span >
                                {this.props.details.labelFormat}
                              
                            </span>
                        </li>
                        <li>
                            <label>Формат</label>
                                <span>
                                    {this.props.details.publisherFormat}
                            </span>
                        </li>

                   


                   
                    <li>
                            <label>Возрастное ограничение</label>
                                  <span>
                                {this.props.details.ageLimit}
                                </span>
                            </li>

                          


                        <li>
                            <label>Год издания</label>
                            <span > {this.props.details.yearOfPublic}</span>
                        </li>
                        <li>
                            <label>Издательство</label>
                            <span>

                                <a href={this.props.details.publisherUrl} >
                                    <span >
                                        {this.props.details.publisherName}</span>
                                </a>
                            </span>
                        </li>

                        <li>
                            <label>Язык</label>
                            <span>
                                {this.props.details.booksLang}</span>
                        </li>

                        <li>
                            <label>ISBN</label>
                            <span>{this.props.details.isbn}</span>
                        </li>
                        <li>
                            <label>Артикул</label>
                            <span>
                                {this.props.details.vendorCode}</span>
                        </li>
                        {
                            this.props.details.weight ?
                            (
                                <li>
                                    <label>Вес в граммах </label>
                                    <span>
                                        {this.props.details.weight} </span>
                                </li>
                                ) : (<>

                                </>)
                        }
                        {
                            this.props.details.pageCount &&
                                <li>
                                    <label>Количество страниц</label>
                                    <span>
                                        {this.props.details.pageCount}
                                    </span>
                                </li>
                                }
                            


                    </ul>
                </div>
                                </div>
                                </>
        );
    }
}

export default BookDetails;