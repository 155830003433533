import React, { useEffect, useState } from 'react';
import StoreChild from '../../components/BooksGrid/StoreChild';
import StorePub from '../../components/BooksGrid/StorePub';
import StoreTatLesson from '../../components/BooksGrid/StoreTatLesson';

import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';

import StoreCatList from '../../components/Category/StoreCatList';
import { Helmet } from "react-helmet";
import OkStatus from "../../components/IconStatus/OkStatus";
import ErrStatus from "../../components/IconStatus/ErrStatus";
import { Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Loader from 'react-loader-spinner';

function UrlRedirect(props) {
    const [loader, setLoader] = useState("LoaderViews");
    const [result, setResult] = useState();
    const [status, setStatus] = useState(0);


    useEffect(() => {
        if (status == 0) {

            LoadSatus(props)
            
        }
    })


    async function LoadSatus(props) {



        const urlTo = window.location.href //
        let formData = new FormData();
        formData.append('urlFrom', urlTo);
        const url = '/api/GetRedirectUrl'
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });
        const data = await response.json()
        window.location.href = data
        setResult(data)
        setStatus(1)
        setLoader("dispnone")



    }

    return (

        <div className="container-fluid pay">

            <Helmet>
                <title>Таткнига - Редирект</title>
                <meta name="description" content="Редирект  " />
            </Helmet>

            <div class="mainbox">
                <div className={loader}>

                    <Loader className="loaderIcons"
                        type="Bars"
                        color="#08a201"
                        height={100}
                        width={100}
                    />
                </div>

                <div className="OkCheck">
                    <OkStatus />
                    <p>Происходит перенаправление</p>
                    
                </div>



                {result == "error" &&
                    <div className="errorCheck">
                        <ErrStatus />
                        <p>Ссылка недействительна. Ccылка, по которой вы перешли, не работает.</p>
                    
                    </div>


                }


                



            </div>
        </div>



    )

}

export default UrlRedirect;