import React, { useState } from 'react';
import PDFViewer from 'pdf-viewer-reactjs'

    const BookReaderPdf = () => {
        return (
            <PDFViewer
                document={{
                    url: '/convert/pdf/Suleymanova.pdf',
                }}


                navbarOnTop
            />
        )
    }

export default BookReaderPdf;

