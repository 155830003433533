import React from 'react';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItem from '../../components/BooksGrid/BookItem';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

class BookLib extends React.Component {



    render() {

        return (
            <div className="content-wrap paddin-zero">
                <div className="main-content no-borders book-page full-width">
                    <ol className="breadcrumb">
                        <li className="title">Категории:</li>
                        
                    </ol>
                    <div className="item-wrap">
                        <div className="detail-page-carousel-wrap">
                            <div className="block-wrap isbn-recommendation-carousel">
                                <div className="block-header">
                                    <h2>Популярное</h2>
                                </div>
                                <div className="block"></div>
                                <div className="item-block">
                                    <div className="item-img">
                                        <i className="icon-mobile-zoom"></i>
                                        <div className="item-img-content"><img src="https://tatkniga.ru/upload/item/4523/picture/445x675/1.jpg" alt="https://tatkniga.ru/upload/item/4523/picture/445x675/1.jpg" className="book-img"/></div>
                                            <div className="copy-content visible-md" data-target=".social-wrap">
                                                <ul className="social-wrap"></ul>
                                            </div>
                                        </div>
                                        <div className="item-info">
                                        <h1> Небесный цветок поэзии. </h1>
                                            <ul className="meta-info hidden-md">
                                                <li>Название на татарском</li>
                                                <li><span>Шигъриятнең күкчәчәге</span></li>
                                            </ul>
                                        <div className="author-info hidden-md">(Автор)&nbsp;<span><a href="/author/1827"><span>Сулейманова Сажида Гадельшевна</span></a></span></div>
                                            <ul className="social-wrap hidden-xs hidden-sm hidden-md"></ul>
                                            <div className="item-excerpt trunc"></div>
                                        </div>
                                        <div className="item-tools">
                                            <div className="checkout-tools">
                                                <div className="copy-content visible-md"></div>
                                                <div className="price-info-wrap">
                                                    <div className="item-info-wrap">
                                                        <div className="copy-content visible-md" data-target=".meta-info">
                                                            <ul className="meta-info">
                                                                <li>Книга</li>
                                                                <li><span></span></li>
                                                            </ul>
                                                        </div>
                                                        <div className="copy-content visible-md" data-target=".author-info">
                                                            <div className="author-info">(автор)&nbsp;<span><span><a href="/author/1596"><span>Якупова Венера Абдулловна</span></a></span></span></div>
                                                        </div>
                                                        <div className="price item-price-wrap hidden-xs hidden-sm"><span className="sale-price">БЕСПЛАТНО</span><span className="list-price"></span><span className="price-save red-text"></span></div>
                                                        <div className="copy-content visible-md visible-xs visible-sm" data-target=".formats-wrap"></div>
                                                    
    
                                                        </div>
                                                    </div>
                                                    <div className="btn-wrap">
                                                        <div className="copy-content visible-xs visible-sm" data-target=".item-price-wrap">
                                                            <div className="price item-price-wrap"><span className="sale-price">0₽</span><span className="list-price"></span><span className="price-save red-text"></span></div>
                                                        </div>
                                                        <div>
                                                            <div className="col">
                                                                <div className="product-item">
                                                                    <div className="pi-pic">
                                                                        <div className="pi-links">
                                                                    <div><Link to="/readpdf" className="btn btn-primary add-to-basket">Читать в PDF</Link></div>
                                                                    <div><Link to="/readepub" className="btn btn-primary add-to-basket">Читать в EPUB</Link></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="copy-content visible-xs visible-sm" data-target=".social-wrap">
                                                            <ul className="social-wrap">
                                                                <li className="label">Поделиться</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="biblio-wrap">
                                            <div className="biblio-info-wrap">
                                                <h2 className="biblio-title">Дополнительная информация</h2>
                                                <ul className="biblio-info">
                                                    <li><label>Переплет</label><span></span></li>
                                                    <li><label>Формат</label><span>84*108/32</span></li>
                                                    <li><label>Год издания</label><span> 2000</span></li>
                                                    <li><label>Издательство</label><span><a href="/publishers/1"><span>-</span></a></span></li>
                                                    <li><label>Язык</label><span>рус</span></li>
                                                    <li><label>ISBN</label><span>2000152402095</span></li>
                                                    <li><label>Артикул</label><span>Э-329/1</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        );
    }
}


export default BookLib;