import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import CartContextProvider from './contexts/CartContext';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing"
import BurgerMenuContextProvider from './contexts/BurgerMenuContext';
//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

Sentry.init({
    dsn: "https://49e0bf7b271d46599a2ea46af37ad89d@o694151.ingest.sentry.io/5783291",
    integrations: [new Integrations.BrowserTracing()],


    tracesSampleRate: 1.0,
});


ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <CartContextProvider>
            <BurgerMenuContextProvider>
                <App />
            </BurgerMenuContextProvider>
        </CartContextProvider>

    </BrowserRouter>,
    rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

