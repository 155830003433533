import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

const accountSideBar = () => {
    return (
        <div>
            <div className="sidebar-section account-sidebar-section">
                <div className="sidebar-section-content">
                    <h2><a rel="nofollow" href="/account">
                        Личный кабинет</a></h2>
                </div>
            </div>

            <div className="sidebar-section account-sidebar-section">
                <div className="sidebar-section-content">
                    <ul className="account-link-list sidebar-nav">
                        <li>
                            <Link to="/account" className="mobile-only">
                                Личный кабинет</Link></li>
                        <li><a rel="nofollow" href="/identity/changepassword">
                            Безопасность</a></li>
                        <li className=""><Link to="/account/contact">
                            Контактные данные</Link></li>

                        <li className=""><Link to="/account/addressbook">
                            Адрес</Link></li>
                        <li className="current-page"><Link to="/account/wishlist">
                            Список к прочтению</Link></li>
                    </ul>
                </div>
            </div>

            <div className="sidebar-section account-sidebar-section">
                <div className="sidebar-section-content">
                    <ul className="account-link-list sidebar-nav">
                        <li className=""><Link to="/account/order/history/1">
                            История заказов</Link></li>
                        <li className=""><Link to="/account/order/history/1">
                            Мои книги</Link></li>
                        <li className=""><Link to="/account/order/tracking/1">
                            Статус заказа</Link></li>

                    </ul>
                </div>
            </div>
        </div>
    );
}


export default accountSideBar;