import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from '../../contexts/CartContext';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';



const MobileNavBtn = () => {
    const { itemCount } = useContext(CartContext);
    const { total } = useContext(CartContext);

    return (
    <div className = "mobilemenu" >
        <div className="row">
           
                <Nav.Link as={Link} to="/" className="navbtnmobile xs-4 sm-4"><span className="bi bi-house-fill bi-mob"></span> Главная </Nav.Link>
                <Nav.Link as={Link} to="/account" className="navbtnmobile xs-4 sm-4"><span className="bi bi-person-lines-fill bi-mob"></span> Личный кабинет</Nav.Link>
            <Nav.Link as={Link} to="/cart" className="navbtnmobile xs-4 sm-4"><span className="bi bi-cart-check bi-mob"></span> Корзина ({itemCount})</Nav.Link >
                
            
        </div>
    </div >
    )
}

export default MobileNavBtn;