import React from 'react';
import { Link } from "react-router-dom";
import { LoginMenu } from '../../components/api-authorization/LoginMenu';

const navTop = () => {


    let urltat = window.location.href;

    let re = "tatkniga.ru";
    let urlnewtat = urltat.replace(re, 'tat.tatkniga.ru');

  


    return(
            
            <div className="user-nav-wrap ">
            <div className="user-nav">
                <ul className="left-nav nav mobile-nav-content" data-order="1">
                    <li><Link to="/" className="home-icon-link"><i className="bi bi-house-fill bi-inline"></i><span className="show-non-desktop">Главная</span></Link></li>
                    <li className="mob-nav-account"><Link to="/about"><i className="bi bi-info-circle bi-inline"></i>О компании</Link></li>
                    <li className="mob-nav-account"><Link to="/account" rel="nofollow"><i className="bi bi-person-lines-fill bi-inline"></i>Личный кабинет</Link></li>
                    <li className="mob-nav-account"><a href={urlnewtat} rel="nofollow"><i className="bi bi-globe  bi-inline"></i> Татарский</a></li>
                    <li className="mob-nav-account"><span style={{ color: 'rgb(67 96 78)' }}><i className="bi bi-globe  bi-inline"></i>Русский</span></li>
                    
              
                </ul>
                <div className="middle-nav">
                   
                            
                        </div>
                <ul className="right-nav mobile-nav-content" data-order="3">
                    
                   
      
                    <LoginMenu> </LoginMenu>
                        </ul>
            </div>
            </div>

    );
}

export default navTop;
