import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

const BookSideBar = () => {


    const [isLoad, setIsLoad] = useState(false);
    const [bannerData, setBannerData] = useState();

    useEffect(() => {
        if (isLoad === false) {

            LoadData();
            setIsLoad(true);

        }
    });
    async function LoadData() {

        const headersReq = new Headers();

        headersReq.append('Content-Type', 'application/json');
        
        const url = '/api/GetBannerSell';

        const res = await
            fetch(url, {
                method: 'get',
                headers: headersReq,

            }).then(response => {
                if (response.status == 200) {
                    return response;
                }
                if (response.status == 500) {
                }
            });
        const data = await res.json();
        console.log(data);
        setBannerData(data);

    }

    return (
<>

            <div className="block-wrap sidebar ">

                <div className="block ">
                    {bannerData &&
                        <div className="tab-wrap module type-link grid tab-8664 tab-active">

                            <div className="tab">

                                <Link to={`/books/${bannerData.bookId}`} className="link-item-image link-item">
                                <img alt={bannerData.nameRus} className="link-image lazy loaded" src={`https://tatkniga.ru/upload/images/books/cover/${bannerData.coverImg}?width=300`} />

                                </Link>

                            </div>
                            <div className="block-header">
                            <h2 className="sidebarTitle2"> {bannerData.nameRus}</h2>
                            </div>

                        </div>
                    }
            </div>
                
            </div>


 </>
    );
}

export default BookSideBar;
