import React, { useContext, useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItems from '../../components/BooksGrid/BookItems';
import PropTypes from 'prop-types';
import { formatNumber } from '../../helpers/utils';
import CartProducts from './CartProducts';
import { CartContext } from '../../contexts/CartContext';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import { Helmet } from "react-helmet";



const Cart = () => {
    const { total, cartItems, itemCount, clearCart, checkout, handleCheckout } = useContext(CartContext);

    const [urlTo, setUrlTo] = useState("/checkout");

    const [load, setLoad] = useState(false);


    useEffect(() => {
        if (load === false) {
            
            if (cartItems.filter(el => el.bookType === 0).length > 0) {
             
                setUrlTo("/checkout");
            }
            else {
                setUrlTo("/echeckout");
            }
            setLoad(true);
        }

       
    })
   
    

    return (
        <>
               <Helmet>
                <title>Татарское книжное издательство - корзина</title>
                    <meta name="description" content="ГУП РТ «Татарское книжное издательство». Магазин книг на татарском языке. " />
              </Helmet>

        <div className="content-wrap paddin-zero" >
          
                <div className="text-center mt-5">
                    <h1>Корзина</h1>
                    <p>Ваша корзина покупок</p>

                   
                </div>

                <div className="row no-gutters justify-content-center">
                    <div className="col-sm-9 p-3">
                        {
                            cartItems.length > 0 ?
                                <CartProducts /> :
                                <div className="p-3 text-center text-muted">
                                    Ваша корзина пуста
                            </div>
                        }

                        {checkout &&
                            <div className="p-3 text-center text-success">
                                <p>Checkout successfull</p>
                                <Link to="/" className="btn btn-outline-success btn-sm">Купить еще</Link>
                            </div>
                        }
                    </div>
                    {
                        cartItems.length > 0 &&
                        <div className="col-sm-3 p-3">
                            <div className="card card-body">
                            <p className="mb-1 item-title ">Всего</p>
                            <h4 className=" mb-3 txt-right item-pirce">{itemCount}</h4>
                            <p className="mb-1 item-title">Итого к оплате:</p>
                            <h3 className="m-0 txt-right item-pirce">{formatNumber(total)}</h3>
                                <hr className="my-4" />
                                <div className="text-center">
                                    <Nav.Link as={Link} to={urlTo} type="button" className="btn btn-green full-width " ><i className="bi bi-check-circle bi-inline"></i>Оформить</Nav.Link>
                                    <button type="button" className="btn btn-red full-width " onClick={clearCart}> <i className="bi bi-x-octagon bi-inline"></i>Удалить</button>
                                </div>

                            </div>
                        </div>
                    }

                </div>
            </div>
     
        </>
    );
}

export default Cart;