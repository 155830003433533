import React, { Component, useContext, useState, useEffect } from 'react';


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import authService from '../../components/api-authorization/AuthorizeService';
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';



const MyLibrary = () => {

    const [library, setLibrary] = useState(null)
    const [loadStatus, setLoadStatus] = useState("not")


    useEffect(() => {

        if (loadStatus == "not") {
            getMyLibrary();

            setLoadStatus("yes")
        }

    });

    async function getMyLibrary() {
        const token = await authService.getAccessToken();
        const url = '/api/GetUserLibrary';
        const headersReq = new Headers();
        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);

        const response = await fetch(url, {
            headers: headersReq
        });
        const data = await response.json()
        
        setLibrary(data)


    };


    async function getEpub(item) {

        const token = await authService.getAccessToken();
        const url = "/api/GetEpubFile/" + item.id;
        const headersReq = new Headers();

        headersReq.append('Authorization', `Bearer ${token}`);

        fetch(url, {
            method: 'GET',
            headers: headersReq,
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "tatkniga-ru-" + item.nameRus + ".epub";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
            });
    };





    async function getPdf(item) {

        const token = await authService.getAccessToken();
        const url = "/api/getPdfFile?id=" + item.id;
        const headersReq = new Headers();

        headersReq.append('Authorization', `Bearer ${token}`);

        fetch(url, {
            method: 'GET',
            headers: headersReq,
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "tatkniga-ru-" + item.nameRus + ".pdf";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
            });
    };





    return (
      


             
                    <div className="container">
                        <div className="library">
                            <p> Ваши книги со временем будут перенесены со старого сайта </p>

                        </div>

                        <div>
                            <div className="card card-body border-0">
                             {library &&
                        

                            (library.data.map((item, idx) =>
                                <div key={item.id}>
                                    <div className="col-sm-2 p-2">
                                        <img
                                            alt={item.nameRus}
                                            style={{ margin: "0 auto", maxHeight: "50px" }}
                                            src={item.coverImg} className="img-fluid d-block" />
                                    </div>
                                    <div className="col-sm-4 p-2">
                                        <h5 className="mb-1">Наименование на татарском:  {item.nameTat}</h5>
                                        <h5 className="mb-1">Наименование на русском: {item.nameRus}</h5>


                                    </div>

                                    <div className="col-sm-4 p-2 text-right">
                                        {item.oldUrl &&
                                            <a className="btn  btn-sm btn-bk mr-2 mb-1" href={'https://old.tatkniga.ru/' + item.oldUrl}> <i className="bi bi-file-text  bi-inline"></i> Читать в старом формате </a>
                                        }
                                        {item.epub &&
                                            <>
                                                <NavLink to={`/EpubReaderLib/${item.id}`} className=" btn btn-sm btn-bk mr-2 mb-1"> <i className="bi bi-file-text  bi-inline"></i> Читать на сайте</NavLink>

                                                <div onClick={()=>getEpub(item)} className="btn  btn-sm btn-bk mr-2 mb-1"> <i className="bi bi-file-earmark-arrow-down"></i>Скачать Epub </div>
                                            </>
                                        }
                                        {item.pdf &&
                                            <>
                                                

                                                <div onClick={() => getPdf(item)} className="btn  btn-sm btn-bk mr-2 mb-1"> <i className="bi bi-file-earmark-arrow-down"></i>Скачать Pdf </div>
                                            </>
                                        }

                                    </div >


                                </div>
                             ))
                    
                           }
    
                            </div>
                        </div>



                    </div>
                
           
      
        )   
}

export default MyLibrary;