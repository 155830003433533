import React, { Component, useContext, useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import ReactDadataBox, { PartyResponseType } from "react-dadata-box";
import { CartContext } from '../../contexts/CartContext';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import authService from '../../components/api-authorization/AuthorizeService';
import useScript from '../Checkout/useScript';
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Col } from 'react-bootstrap';

const Token = "bc1e08252bb6e427d906201f63c565bcc242c0e3";





const Echeckout = (props) => {



    const { register, handleSubmit, watch, errors } = useForm();

    const [address, setAddress] = useState({});
    const [loadaddress, setLoadAddress] = useState("loadDisp");

    const [countryId, setCountryId] = useState();
    const [city, setCity] = useState();
    const [region, setRegion] = useState();
    const [otherDeliveryValue, setOtherDeliveryValue] = useState();
    const [house, setHouse] = useState();
    const [street, setStreet] = useState();
    const [flat, setFlat] = useState();
    const [postalCode, setPostalCode] = useState();
    const [addressFullView, setAddressFullView] = useState("loadDisp");
    const [addressDadata, setAddressDadata] = useState("loadDisp");
    const [addressOther, setAddressOther] = useState("loadDisp");
    const [dispShipDataLoad, setDispShipDataLoad] = useState("loadDisp");
    const [dispShipDataView, setDispShipDataView] = useState("viewDisp");

    const [deliveryOtherType, setDeliveryOtherType] = useState("loadDisp");
    const [selectShip, setSelectShip] = useState();
    const [selectDeliveryTypeId, setSelectDeliveryTypeId] = useState();
    const [phone, setPhone] = useState();
    const [dateDelivery, setDateDelivery] = useState();
    const [btnName, setBtnName] = useState("Оплатить")
    const { total, cartItems, itemCount, clearCart, checkout, handleCheckout } = useContext(CartContext);
    const [delivery, setDelvery] = useState("loadDisp");

    const [allType, setAllType] = useState("loadDisp");


    const [payDisp, setPayDisp] = useState("loadDisp");







    const [loader, setLoader] = useState("loaderNone");

    const [payloader, setPayloader] = useState("loaderNone");


    const [err, setErr] = useState("errNone");


    const [helpData, setHelpData] = useState("");


    const [deliveryType, setDeliveryType] = useState();
    const [paymentType, setPaymentType] = useState();
    const [totalPrc, setTotalprc] = useState(total);
    const [shipPrc, setShipprc] = useState(0);
    const [isLoadCountries, setisLoadCountries] = useState(false);
    const [shipping, setShipping] = useState();
    const [pay, setPay] = useState();
    const [loaderDelivery, setLoaderDelivery] = useState("loadDisp");
    const [payUrl, setPayUrl] = useState("/checkout");
    const [payView, setPayView] = useState("loadDisp");
    const [orderDataView, setOrderDataView] = useState("viewDisp");
    const [countries, setCountries] = useState();



    


    useEffect(() => {

        document.title = `Оформление заказа`;

        if (isLoadCountries === false) {

            LoadCountries();

            setisLoadCountries(true);

        }
     
    })

    async function LoadCountries() {
        const token = await authService.getAccessToken();
        const headersReq = new Headers();

        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);
        const url = `https://tatkniga.ru/api/GetCountries`;

        const res = await
            fetch(url, {
                method: 'get',
                headers: headersReq,

            }).then(response => {
                if (response.status == 200) {
                    return response;
                } if (response.status == 401) {

                    authService.signOut();
                }
            });
        const data = await res.json();

        setCountries(data);

    }

    async function handleCountryChange(event) {


        setCountryId(event.target.value);
  


    }


   

    const onSubmit = (data) => {

        createOrder(data);



    };
    async function SignOutCheck() {
        authService.signOut()
    }
    

    async function createOrder(data) {


        let datas = {
            "data": data,
            "items": cartItems,
         
            "deliveryPrice": 0,
        }


        setLoader("loaderView")

        const token = await authService.getAccessToken()


        const headersReq = new Headers();

        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);


        const res = await
            fetch('/api/CreateEOrder', {
                method: 'post',
                headers: headersReq,

                body: JSON.stringify(datas)
            });

        let d = await res.json()

        if (d['type'] != "Error") {

            setLoader("loaderNone")


            setPayUrl(d['confirmation_url']);
            setOrderDataView("loadDisp");
            setPayView("viewDisp");
            window.scrollTo(0, 0);
            clearCart();
            window.location.href = d['confirmation_url'];

            return false;

        }
        else {
            setLoader("loaderNone");
            setErr("errData");
        }



    }
   

   

    return (
        <div>


            <div className="content-wrap">
                <div className="main-content checkout-page payment-step checkout-homer address-validation iframe-enabled no-delivery-country-selected ">
                    <ol className="steps">
                        <li className="complete">Детали заказа</li>
                        <li>Оплата</li>
                    </ol>



                    <div className={`block-wrap content-block existing-customer-block ${orderDataView}`} >
                        <div className="row block-wrap-content">

                            <div className={err}>
                                Ошибка. Попробуйте снова. При повторении ошибки напишите письмо support@tatkniga.ru
                            </div>




                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="col-xs-12">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            ФИО
                                        </label>
                                        <input name="FullName" className="form-control" placeholder="Введите Ваше ФИО" ref={register({
                                            required: true, maxLength: 500,
                                            pattern: {

                                                value: /^[a-zA-Z\u0400-\u04ff ,.'-]{2,}\s[a-zA-Z\u0400-\u04ff ,.'-]+$/u,
                                                message: "Введите ФИО"

                                            }
                                        })} />

                                    </div>
                                </div>



                                <div className="col-xs-12">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            Телефон
                                        </label>
                                        <PhoneInput
                                            className="form-control"
                                            placeholder="Введите номер телефона"
                                            value={phone}
                                            onChange={setPhone} />
                                    </div>
                                </div>

                                <div className="col-xs-12 loadDisp">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            Телефон
                                        </label>
                                        <input name="Phone" className="form-control" value={phone} ref={register({ required: true, maxLength: 350 })} />

                                    </div>
                                </div>

                               
                                <div className="col-xs-12">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            Выберите страну
                                        </label>
                                        <select className="form-control" onChange={(event) => handleCountryChange(event)} name="CountryId" ref={register({ required: true })} >
                                            <option value="0" > Выберите страну</option>
                                            {countries ? (countries.map(country =>
                                                <option key={country.countriesId} value={country.countriesId}>{country.country}</option>
                                            )
                                            ) : (<option>ошибка</option>)

                                            }
                                        </select>

                                    </div>
                                </div>


                                    <div className="col-xs-12">
                                        <div className="form-group address-form-col text-center">
                                            <button type="submit" className="btn fullwidthbtn btn-green ">{btnName}</button>
                                        </div>
                                    </div>
                           





                            </form>


                        </div>


            

                    </div>



                    <div className={`block-wrap content-block existing-customer-block ${payView}`} >
                        <div className="col-xs-12">
                            <div className="form-group address-form-col text-center">

                                <a href={payUrl} className="btn btn-green fullwidthbtn"><i className="bi bi-check-circle bi-inline"></i>Перейти для оплаты</a>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="block-wrap show-only-mobile">
                    <div className="toggle-component">
                        <div className="wrapper">
                            <Link to="/support" className="btn-white" > <i className="bi bi-question-octagon"></i>Не получается оформить заказ?</Link>
                        </div>
                    </div>
                </div>



                <div className="sidebar right">
                    <div className="mini-basket">

                        <div className="heading">
                            <h2>Заказ</h2>
                            <i className="icon-basket"></i>
                            <span className="item-count">
                                {itemCount}</span>
                            <span className="item-total">{total}</span>
                        </div>

                        <div className="wrapper">


                            {
                                cartItems.map((product, idx) =>

                                    <dl key={idx} className="item-row">
                                        <dt>
                                            {product.nameRus}
                                            <br />
                                            <span className="bk-qty">
                                                {product.quantity} шт.</span>
                                        </dt>
                                        <dd className="item-price text-right"></dd>
                                    </dl>


                                )
                            }



                    


                            <dl>
                                <dt className="total"><strong>
                                    Итого</strong></dt>
                                <dd className="text-right total-price">{totalPrc}</dd>

                            </dl>

                            <Link to="/support" className="btn-white" ><i className="bi bi-question-octagon"></i> Не получается оформить заказ?</Link>
                        </div>
                    </div>
                </div>




            </div>



        </div>
    );
}

export default Echeckout;
