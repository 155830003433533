import React from 'react';
import { Link } from "react-router-dom";


class NewsItems extends React.Component {

    render() {
        return (
            <div class="col-xs-6 col-md-4">
            <div className="xgnews-thumbnail-10">
                <div className="thumb">
                        <Link to={this.props.url}>     <img src={this.props.image + "?width=350&height=272"} /></Link>
                    <div className="content-news">
                        <div className="inner-content">
                        
                                <h4 className="title-news"><Link to={this.props.url}>{this.props.title}</Link></h4>
                                
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    };
}

export default NewsItems;

