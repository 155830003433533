import React from 'react';

import {BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
class BookItem extends React.Component {
    render() {
        return (
            <div className="book-item">
                <div className="item-img">
                    <Link to="/audio-book">
                        <img
                            className="lazy loaded"
                            alt="Небесный цветок поэзии"
                            src="https://tatkniga.ru/media-book/rus/img_b2.jpg"/>
                     
                    </Link>
                </div>

                <div className="item-info">
                    <h3 className="title">
                        <Link to="/audio-book">
                           Япон хикәяте
</Link>
                    </h3>
                    <p className="author">
                        <span >
                            
                            <span>Габдулла Тукай</span>
                           
                        </span>
                    </p>
                    <div className="rating-wrap">
                        <div aria-label="Рейтинг">
                            <div className="stars">
                                <span className="star full-star"></span>
                                <span className="star full-star"></span>
                                <span className="star full-star"></span>
                                <span className="star full-star"></span>
                                <span className="star half-star"></span>
                            </div>
                        </div>
                    </div>

                    <p className="format">
                        Аудио-книга</p>

                
                </div>

                <div className="item-actions">
                    <div className="btn-wrap">
                        <Link
                            rel="nofollow"
                            to="/audio-book/"
                            className="btn btn-sm btn-primary add-to-basket"
                         >
                            Слушать</Link>
                    </div>
                </div>
            </div>

        );

    }
}

export default BookItem;
