import React from 'react';
import Brcrumb from '../../components/Book/Brcrumb';
import BookItemBlock from '../../components/Book/BookItemBlock';
import BookDetails from '../../components/Book/BookDetails';
import PropTypes from 'prop-types';
class Book extends React.Component {

    constructor(props) {
        super(props);
        this.state = { books: [], loading: true };

    }
    componentDidMount() {
        this.bookData();
    }



    static renderBook(books) {
        return (


            <div className="content-wrap paddin-zero" >
                <div className="main-content no-borders book-page full-width">
                    <Brcrumb genreslist={books} />

                    <div className="item-wrap">


                        <div className="detail-page-carousel-wrap">
                            <div className="block-wrap isbn-recommendation-carousel">

                                <div className="block"></div>

                                <BookItemBlock books={books.bookViewModel} authors={books} />
                                <BookDetails details={books.bookViewModel} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };




    render() {
        let contents = this.state.loading
            ? <p><em>...</em></p>
            : Book.renderBook(this.state.books);

        return (
            <div>

                <p></p>
                {contents}
            </div>
        );
    }

    async bookData() {
        const id = this.props.match.params.id;
        console.log(this.props.match.params.id);
        const response = await fetch('/api/audio/' + id);
        const data = await response.json();
        console.log(data);

        this.setState({ books: data, loading: false });


    }

}
export default Book;


