
import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import BookSidebar from "./BookSidebar";
import VkWidget from "./VkWidget";
const SideBarList = () => {
    return (


        <>
            <BookSidebar />
            <VkWidget/>
        </>
    );
}

export default SideBarList;

