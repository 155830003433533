import React, { Component, useContext, useState, useEffect } from 'react';
import HeadLayout from '../../components/HeadLayout/HeadLayout';
import FooterWrap from '../../components/Footer/FooterWrap';
import MobileNavBtn from '../../components/HeadLayout/MobileNavBtn';
import EmailSubscribeFrame from '../EmailSubscribe/EmailSubscribeFrame';
import './Layout.css';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu'
import { BurgerMenuContext } from '../../contexts/BurgerMenuContext';



export default function Layout(props) {


    function urltat() {
        let ulrtat = window.location.href;

        let re = "tatkniga.ru";
        let ulrtatnew = ulrtat.replace(re, 'tat.tatkniga.ru');
        window.location.href = ulrtatnew;
    }

    const ctx = useContext(BurgerMenuContext)
        return (
        <>
                
                <Menu
                    customBurgerIcon={false}
                        isOpen={ctx.isMenuOpen}
                        pageWrapId={"page-wrap"}
                    onStateChange={(state) => ctx.stateChangeHandler(state)}
                    
                >
                    <div className="burger-nav burger-nav-top lang-nav">
                        <ul>
                            <li><a onClick={urltat} className="tatbtn menu-item">Татарский </a></li>
                            <li><div  className="rusbtn menu-item">Русский </div></li>
                        </ul>
                    </div>
                    <div className="burger-nav burger-nav-top">
                        <ul>

                            <li><Link className="menu-item " to="/" onClick={ctx.toggleMenu} >Главная</Link></li>
                            <li><Link className="menu-item" to="/store" onClick={ctx.toggleMenu} >Магазин</Link></li>
                            <li><Link className="menu-item" to="/sales" onClick={ctx.toggleMenu} >Акции</Link></li>
                            <li><Link  className="menu-item" to="/library" onClick={ctx.toggleMenu} >Библиотека</Link></li>
                            <li><Link className="menu-item" to="/audio-books" onClick={ctx.toggleMenu} >Аудиокниги</Link></li>
                            <li><Link className="menu-item" to="/newslist" onClick={ctx.toggleMenu} >Новости</Link></li>
                            <li><Link className="menu-item" to="/our-stores" onClick={ctx.toggleMenu} >  Фирменные магазины <br /> <span className="map-contact">(на карте)</span></Link></li>
                            <li><a className="menu-item" href="/upload/xlsx/tatkniga-ru-price.xlsx" onClick={ctx.toggleMenu} download >Скачать прайс-лист</a></li>
                         </ul>
                    </div>

          

                    <div className="burger-nav burger-nav-btn">
                        <ul>
                            
                            <li><Link className="menu-item" to="/about" onClick={ctx.toggleMenu}>О компании</Link></li>
                            
                            <li><Link className="menu-item" to="/support" onClick={ctx.toggleMenu}>Служба поддержки</Link></li>
                        
                         </ul>
                    </div>
                    <div className="marketing">
                    <div className="burger-nav burger-nav-contact shopstore ">
                        <ul>
                            <li>МАРКЕТИНГ</li>
                            <li>tki_marketing@mail.ru</li>
                            <li><a href="tel:+78435194535"> +7(843)519-45-35</a></li>
                        </ul>

                    </div>


                    <div className="burger-nav burger-nav-contact shopstore">
                        <ul>
                            <li>Магазин №1</li>
                            <li>г.Казань, ул.Баумана, 51 (ТК ГУМ, 3-ий этаж)</li>
                            <li>Понедельник —Воскресенье 10:00 — 22:00</li>
                            <li><a href="tel:+78432257655">+7 (843) 225-76-55 </a></li>
                        </ul>

                    </div>

                    <div className="burger-nav burger-nav-contact shopstore">
                        <ul>
                            <li>Магазин №2</li>
                            <li>г.Казань, ул.Декабристов, д.2 ("Литературное кафе в здании ТАТМЕДИА") - 1 ЭТАЖ</li>
                                <li>Будние дни 08:00 — 18:00 </li>
                                <li><a href="tel:+79276710500">  +7 (927) 671-05-00 </a></li>
                              
                            
                        </ul>

                    </div>

                    <div className="burger-nav burger-nav-contact shopstore">
                        <ul>
                            <li>Магазин №3</li>
                            <li>г.Казань, ул.Баумана 19</li>
                            <li>Понедельник — Воскресенье 10:00 — 20:00</li>
                            <li><a href="tel:+78432947050">+7 (843) 294-70-50 </a></li>
                        </ul>

                        </div>
                    </div>

                </Menu>
             
                <HeadLayout />
        
                
                {props.children}
                <FooterWrap />

                <MobileNavBtn />

            </>
        );
    
}
