import React from 'react';

import { Link } from "react-router-dom";
import './404.css';
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
    return (

        <>
          <Helmet>
           <title>Таткнига - 404 ошибка</title>
          <meta name="description" content="Ошибка 404! Эта страница не найдена! " />
         </Helmet>

            <div class="mainbox">
                
                
                <div class="err">404</div>
                <div class="msg">Эта страница не найдена!  <p>Вернуться на <strong><Link to="/">главную</Link></strong> и попробовать снова</p></div>
            

            </div>
          </>
    );
}

export default NotFoundPage;
