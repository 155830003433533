import React from 'react';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItems from '../../components/BooksGrid/BookItems';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import SideBarList from '../../components/Sidebar/SideBarList';


import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ContentLoader from 'react-content-loader'
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';

import { Helmet } from "react-helmet";
class Sales extends React.Component {

    constructor(props) {
        super(props);
        this.state = { GenreBooks: [], Meta: [], loading: true, nexturl: false, pervurl: false };

    }

    componentDidMount() {
        this.genreBooksData();
        
    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.genreBooksData();

            window.scrollTo(0, 0);
        }
    }

  



    static renderGenreBooks(Meta, GenreBooks, nexturl, pervurl) {
        return (

            <>
                <Helmet>
                    <title>Татарское книжное издательство - акции </title>
                    <meta name="description" content="" />
                </Helmet>
                <div className="main-content  category-page">
                    <div className="content-block paddin-zero">
                        <div className="block-wrap  paddin-zero">
                            <div className="block">
                                <div className="block-header">
                                    <h2 className="titleHead">Акции</h2>
                                </div>

                                <div className="tab-wrap module type-book grid">
                                    <div className="tab search">
                                        {GenreBooks.results.map((results, idx) =>

                                            <BookItems key={idx} id={results.id} results={results} />

                                        )
                                        }


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="foot-block">
                        <div className="text-center">


                            {pervurl ? (

                                <Link to={GenreBooks.previousUrl}> <div className="btnP btn">Назад </div></Link>

                            ) : (
                                <div>

                                </div>
                            )
                            }



                            {nexturl ? (

                                <Link to={GenreBooks.nextUrl}> <div className="btnN btn">Далее </div></Link>

                            ) : (
                                <div>

                                </div>
                            )
                            }





                        </div>
                    </div>





                </div>

            </>


        )
    };





    render() {
        let contents = this.state.loading
            ? <>


                <div className="main-content  category-page">



                    <div className="content-block">
                        <div className="block-wrap">
                            <div className="block">
                                <div className="block-header">
                                    <h2 className="titleHead">Акции</h2>
                                </div>

                                <div className="tab-wrap module type-book grid">
                                    <div className="tab search">
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            : Sales.renderGenreBooks(this.state.Meta, this.state.GenreBooks, this.state.nexturl, this.state.pervurl);

        return (
            <div>

    
                {contents}
            </div>
        );
    }


    async genreBooksData() {

        const id = this.props.match.params.id;
        



        const search = this.props.location.search; // 
        const params = new URLSearchParams(search);
        let pg = params.get('PageNumber'); // 

        
        if (pg == null) {
            pg = 1;
        }




        const response = await fetch('/api/GetSales?id=0' + '&PageNumber=' + pg + '&BookType=3');

        const meta = JSON.parse(response.headers.get('x-pagination'));






        const data = await response.json();

        


        this.setState({ GenreBooks: data, Meta: meta, loading: false, nexturl: meta.HasNext, pervurl: meta.HasPrevious });
        

    }

}

export default Sales;













