
import React from 'react';
import LastBook from '../../components/BooksGrid/LastBook';
import PopularBook from '../../components/BooksGrid/PopularBook';
import TopAudio from '../../components/BooksGrid/TopAudio';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import SideBarList from '../../components/Sidebar/SideBarList';
import NewEbookSell from '../../components/BooksGrid/NewEbookSell';
import ImgHomeBnr from '../../components/ImageBanner/ImgHomeBnr';
import { Helmet } from "react-helmet";

class Home extends React.Component {
    render() {
        return (

            <>
               <Helmet>
               <title>Татарское книжное издательство - главная</title>
                    <meta name="description" content="ГУП РТ «Татарское книжное издательство». Магазин книг на татарском языке. " />
              </Helmet>




           
                
                <div className="main-content no-borders home-page">

                    <div className="content-block">
                        <div className="block-wrap paddin-zero ">
                         <div className="block-header">
                                <h2 className="titleHead">Новинки</h2>
                            </div>
                        <div className="block ">
                                <LastBook/>
                        
                        </div>
                    </div>
                    </div>
                    <div className="content-block">
                        <div className="block-wrap paddin-zero ">
                         <div className="block-header">
                                <h2 className="titleHead">Популярное</h2>
                            </div>
                        <div className="block ">
                                <PopularBook/>
                        
                        </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="block-wrap paddin-zero ">
                            <div className="block-header">
                                <h2 className="titleHead">Аудиокниги</h2>
                            </div>
                            <div className="block ">
                                <TopAudio />

                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="block-wrap paddin-zero ">
                            <div className="block-header">
                                <h2 className="titleHead">Электронные книги</h2>
                            </div>
                            <div className="block ">
                                <NewEbookSell />

                            </div>
                        </div>
                    </div>
            
                    </div>
         
                </>
           
        );
    }
}
export default Home;