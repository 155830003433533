import React, { Component, useState, useEffect } from 'react';

import BookItems from '../../components/BooksGrid/BookItems';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import { useForm } from "react-hook-form";
import SideBarList from '../../components/Sidebar/SideBarList';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ContentLoader from 'react-content-loader';
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';

export default function Genres(props) {


    const [isPartner, setIsParner] = useState();
    const [isSales, setIsSales] = useState();
    const [LangId, setLangId] = useState();
    const [idgenre, setIdgenre] = useState();
    const [PriceFrom, setPriceFrom] = useState();
    const [PriceTo, setPriceTo] = useState();
    const [loading, setLoading] = useState(true);
    const [filterloading, setFilterloading] = useState(true);
    const [GenreBooks, setGenreBooks] = useState([]);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const [priceSort, setPriceSort] = useState(1);

    

    async function loadData(props) {
        
        const id = props.match.params.id;
        setIdgenre(id);
        const search = props.location.search; // 
        const params = new URLSearchParams(search);
        let _isSales = params.get('isSales'); // 

        let _isPartner = params.get('isPartner')
        let _LangId = params.get('LangId')
        

        let url = '/api/GetGenreBooks?id=' + id;

        if (_isPartner != null) {
            url = url + '&isPartner=' + _isPartner;
            setIsParner(_isPartner);
        }
        if (_isSales != null) {
            url = url + '&isSales=' + _isSales;
            if (_isSales == 1) {
                setIsSales(true)
            }
            else
            {
                setIsSales(false);
            }
            
        }
    
        url = url + '&PriceFrom=0' + '&PriceTo=99999';

     
    

        const response = await fetch(url);
        const data = await response.json();
        setGenreBooks(data)
        setLoading(false);
        setFilterloading(false)


    }
    const onSubmit = data => {
    
        LoadDataFilter(data);
      


    }

    async function LoadDataFilter(_data) {
        

        setFilterloading(true)
        let url = '/api/GetGenreBooks?id=' + idgenre;
       

        if (_data._isPartner != null) {
            url = url + '&isPartner=' + _data._isPartner;
            setIsParner(isPartner);
        }
        if (_data._isSales != null) {
            if (_data._isSales == true) {
                url = url + '&isSales=' + 1;
                setIsSales(true)
            }
            else {
                url = url + '&isSales=' + 0;
                setIsSales(false)
            }
            
        }

        if (_data._priceFrom != null && _data._priceTo != null) {
            url = url + '&PriceFrom=' + _data._priceFrom + '&PriceTo=' + _data._priceTo;
            setPriceFrom(_data._priceFrom);
            setPriceTo(_data._priceTo);
        }
        else {
            url = url + '&PriceFrom=0' + '&PriceTo=99999';
       

        }
        if (LangId != null && LangId != "0") {
            url = url + '&LangId=' + LangId;
            
        }
        
        const response = await fetch(url);
        const datares = await response.json();

        if (datares.results.length > 0) {

        
            let byPrice = datares.results.slice(0);
            if (priceSort == 1) {
                
                byPrice.sort(function (a, b) {
                    return a.price - b.price;
                });
            }
            if (priceSort == 2) {
                byPrice.sort(function (a, b) {
                    return b.price - a.price;
                });
            }


            let datasorted = {
                nextUrl: null,
                previousUrl: null,
                type: "res",
                results: byPrice
            }
            
            setGenreBooks(datasorted)
            setFilterloading(false)


        }

        else {

            
            setGenreBooks(datares)
            setFilterloading(false)
        }
        
    }




    useEffect(() => {

        document.title = `Татарское книжное издательство`;


        if (loading ===true ) {
            loadData(props);

            window.scrollTo(0, 0);
            

        }
     


        

    })


    return (

        <div>


            <div>
                <div className="content-wrap">
                    <div className="sidebar">
                       

						<div className="sidebar-section search-filter">
							<h2>Фильтр</h2>
							<div className="sidebar-section-content search-filter-sidebar">
                                <form className="filter-menu" onSubmit={handleSubmit(onSubmit)}>
								
							
									<div className="form-group">
                                        <label htmlFor="filter_price">Язык издания</label>
                                        <select className="form-control" name="_langId" id="_langId" value={LangId} onChange={e => setLangId(e.target.value)} ref={register}>
											<option value="0" label="Все">	Все</option>
                                            <option value="1" label="татарский"> татарский</option>
                                            <option value="2" label="русский"> русский</option>
                                            <option value="3" label="русский/татарский"> русский/татарский</option>
                                            <option value="4" label="татарский/русский/английский">татарский/русский/английский</option>
                                            <option value="5" label="английский">английский</option>
                                            <option value="6" label="удмуртский/русский/татарский/английский">удмуртский/русский/татарский/английский</option>
								
										</select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="filter_price">Сортировка по цене </label>
                                        <select className="form-control" name="_langId" id="_PriceSort" value={priceSort} onChange={e => setPriceSort(e.target.value)} ref={register}>
                                            
                                            <option value="1" label="По возрастанию">По возрастанию</option>
                                            <option value="2" label="По убыванию">По убыванию</option>
                                 

                                        </select>
                                    </div>



									<div className="form-group">
                                        <label htmlFor="filter_availability">Парнтерские книги</label>
                                        <input type="checkbox" style={{ float: "left" }} name="_isPartner"  ref={register} />
									</div>

									<div className="form-group">
                                        <label htmlFor="filter_search_lang">Акции</label>
                                        <input type="checkbox" style={{ float: "left" }} name="_isSales"   ref = { register } />
									</div>

							

									<div className="form-group padded-btn-wrap">
										<button type="submit" className="btn btn-primary btn-my-search">
											Поиск</button>
									</div>
								</form>
                            </div>

						</div>

                        <BookSidebar />


                    </div>
                    <div className="main-content  category-page">
                        <div className="content-block">
                            <div className="block-wrap">
                                <div className="block">
                                    <div className="tab-wrap module type-book grid">

                                        <div className="tab search">
                                            {(loading === true || filterloading === true )  &&
                                                <>
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                    <BookItemLoader />
                                                </>


                                            }
                                   
                                  
                                            {(loading === false && filterloading === false) &&
                                                <>

                                                    {GenreBooks ? (

                                                    <>
                                                        {GenreBooks.results.length > 0 &&
                                                            <>

                                                                {GenreBooks.results.map((results, idx) =>

                                                                    <BookItems key={idx} id={results.id} owl="none" results={results} />


                                                                )

                                                                }
                                                            </>
                                                        }
                                                        
                                                        {GenreBooks.results.length === 0 &&



                                                            <div className="no-book"><p>Извините. На данный момент нет  доступных книг по Вашему запросу</p></div>
                                                        }

                                                        </>
                                                    )
                                                    : (<div className="no-book"><p>Извините. На данный момент нет  доступных книг по Вашему запросу</p></div>)
                                                    }

                                                </>
                                            }







                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            
        </div>
        )

}
