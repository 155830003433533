import React from 'react';


const searchBar=()=>{
	return(

<div className="sidebar-section search-filter">
	<h2>Фильтр</h2>
	<div className="sidebar-section-content search-filter-sidebar">
		<div className="filter-menu">		

			<input type="hidden" name="searchSortBy" value=""/>
			<input type="hidden" name="category" value=""/>
	



			<div className="form-group">
				<label for="filter_search_lang">Язык</label>
				<select className="form-control" name="searchLang" id="filterLang">
					<option value="" label="Все" selected="selected">
							Все</option>
					<option value="123" label="Татарский">
							Татарский</option>
					<option value="137" label="Русский">
							Русский</option>
                            <option value="137" label="Русский/Татарский">
							Русский/Татарский</option>
                            
                            <option value="137" label="Английский">
							Английский</option>
	
					</select>
			</div>

			
			<div className="form-group">
				<label for="filter_search_lang">Доступность</label>
				<select className="form-control" name="searchLang" id="filterLang">
					<option value="" label="В наличии" selected="selected">
							В наличии</option>
					<option value="123" label="Под заказ">
							Под заказ</option>
				
                            
                    </select>
			</div>

	

			<div className="form-group padded-btn-wrap">
				<button type="submit" className="btn btn-primary">
					Показать результаты</button>
			</div>
		</div>
	</div>
</div>
	);
}


export default searchBar;