import React, { useRef, useContext, useEffect, useState} from 'react'
import {
  EpubViewer,
  ReactEpubViewer
} from 'react-epub-viewer'
import authService from '../../components/api-authorization/AuthorizeService';


import {
    EpubView, // Underlaying epub-canvas (wrapper for epub.js iframe)
    EpubViewStyle, // Styles for EpubView, you can pass it to the instance as a style prop for customize it
    ReactReader, // A simple epub-reader with left/right button and chapter navigation
    ReactReaderStyle // Styles for the epub-reader it you need to customize it
} from "react-reader";

const EpubReaderLib = (props) => {
    const viewerRef = useRef(null);
    
    const [loadStatus, setLoadStatus] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        if (loadStatus === false) {

            LoadData();

        }
    });

    ////
    const [location, setLocation] = useState(null)

    const [page, setPage] = useState('')
    const renditionRef = useRef(null)
    const tocRef = useRef(null)
    const locationChanged = (epubcifi) => {
        if (renditionRef.current && tocRef.current) {
            const { displayed, href } = renditionRef.current.location.start
            const chapter = tocRef.current.find((item) => item.href === href)
            setPage(`Страница ${displayed.page} из ${displayed.total} в главе ${chapter ? chapter.label : 'n/a'}`)
        }
        setLocation(epubcifi)
    }
    /////


    async function LoadData() {

        const id = props.match.params.id;
        
        const token = await authService.getAccessToken()
        const headersReq = new Headers();
        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);
      
        const url = `/api/EpubUrlLib/${id}`;
        
        const res = await
            fetch(url, {
                method: 'get',
                headers: headersReq,

            }).then(response => {
                if (response.status == 200) {
                    
                    return response;
                } if (response.status == 401) {

                    authService.signOut();
                }
                if (response.status == 500) {

                    alert("error");
                }
            });


        const data = await res.json();
        
        setData(data);
  
        setLoadStatus(true);
    }



        return (
            <div className="bookreaderepub">
               
                {loadStatus == true &&
              
                    <>
          


                    <ReactReader
                        location={location}
                        locationChanged={locationChanged}
                        url={data.url}
                        title={data.nameTat}
                        getRendition={(rendition) => renditionRef.current = rendition}
                        tocChanged={toc => tocRef.current = toc}
                
                    />
                    <div style={{textAlign: 'center', zIndex: 1 }}>
                    {page} 
                    </div>
                    </>
                }
                
                </div>



        
        );
}

export default EpubReaderLib;