import React, { useContext } from 'react';
import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from '../../components/icons/index'
import { CartContext } from '../../contexts/CartContext';

import { formatNumber } from '../../helpers/utils';

const CartItem = ({ product }) => {

    const { increase, decrease, removeProduct } = useContext(CartContext);

    return (
        <div className="row no-gutters py-2">
            <div className="col-sm-2 p-2">
                <img
                    alt={product.name}
                    style={{ margin: "0 auto", maxHeight: "50px" }}
                    src={product.coverImg} className="img-fluid d-block" />
            </div>
            <div className="col-sm-4 p-2">
                <h5 className="mb-1">Наименование на татарском:  {product.nameTat}</h5>
                <h5 className="mb-1">Наименование на русском: {product.nameRus}</h5>
                <h5 className="mb-1 item-total">Всего на складе: {product.totalQuantity}</h5>
                <p className="mb-1">Цена: {formatNumber(product.price)} </p>

            </div>
            <div className="col-sm-2 p-2 text-center ">
                <p className="mb-0">Количество: {product.quantity}</p>
            </div>
            <div className="col-sm-4 p-2 text-right">
                {product.totalQuantity > product.quantity &&
                <button
                    onClick={() => increase(product)}
                    className="btn  btn-sm mr-2 mb-1">
                    <PlusCircleIcon width={"20px"} />
                </button>
                 }
                {
                    product.quantity > 1 &&
                    <button
                        onClick={() => decrease(product)}
                        className="btn  btn-sm mb-1">
                        <MinusCircleIcon width={"20px"} />
                    </button>
                }

                {
                    product.quantity === 1 &&
                    <button
                        onClick={() => removeProduct(product)}
                        className="btn btn-sm mb-1">
                        <TrashIcon width={"20px"} />
                    </button>
                }

            </div>
        </div>
    );
}

export default CartItem;