import React from 'react';
import Brcrumb from '../../components/Book/Brcrumb';
import BookItemBlock from '../../components/Book/BookItemBlock';
import BookDetails from '../../components/Book/BookDetails';
import PropTypes from 'prop-types';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItem from '../../components/BooksGrid/BookItem';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ImageGallery from 'react-image-gallery';
import Zoom from 'react-medium-image-zoom'
import "react-image-gallery/styles/css/image-gallery.css";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";




class NewsData extends React.Component {

    constructor(props) {
        super(props);
        this.state = { news: [], loading: true };

    }
    componentDidMount() {
        this.bookData();
    }



    static renderBook(news) {
        return (



            <div className="main-content  category-page">




            <div className="content-block padding-zero">
                <div className="block-wrap">
                    <div className="block">

                        <div className="tab-wrap module type-book grid">

                            <div className="col-xs-12 newstext ">
                                <h3>
                                    <div
                                        className="item-excerpt trunc" dangerouslySetInnerHTML={{ __html: news.title }}>
                                    </div>
                                </h3>
                                <Zoom>
                                    <LazyLoadImage
                                        alt=""
                                        height=""
                                        src={news.image} // use normal <img> attributes as props
                                        width="100%" />
                                </Zoom>
                                <span></span>

                                <div
                                    className="item-excerpt trunc article-body" dangerouslySetInnerHTML={{ __html: news.content }}>
                                </div>
                                {news.images &&
                                    <div>
                                 
                                        <ImageGallery items={news.images} />

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </div>
            
        )
    };




    render() {
        let contents = this.state.loading
            ? <p><em>...</em></p>
            : NewsData.renderBook(this.state.news);

        return (
            <div>

                <p></p>
                {contents}
            </div>
        );
    }

    async bookData() {
        const id = this.props.match.params.id;
        console.log(this.props.match.params.id);
        const response = await fetch('/api/postview?id=' + id);
        const data = await response.json();
        console.log(data);

        this.setState({ news: data, loading: false });


    }

}
export default NewsData;


