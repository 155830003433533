
import React, { Component, useState, useEffect } from 'react';

import StoreChild from '../../components/BooksGrid/StoreChild';
import StorePub from '../../components/BooksGrid/StorePub';
import { useForm } from "react-hook-form";

import StorePoetry from '../../components/BooksGrid/StorePoetry';
import BookItems from '../../components/BooksGrid/BookItems';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';
import SideBarList from '../../components/Sidebar/SideBarList';

import StoreCatList from '../../components/Category/StoreCatList';
import { Helmet } from "react-helmet";









const Store = () => {




    const [isPartner, setIsParner] = useState();
    const [isSales, setIsSales] = useState();
    const [LangId, setLangId] = useState();
    const [idgenre, setIdgenre] = useState(1);
    const [PriceFrom, setPriceFrom] = useState();
    const [PriceTo, setPriceTo] = useState();
    const [genreId, setGenreId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [filterUse, setFilterUse ]=useState(false)
    const [filterloading, setFilterloading] = useState(true);
    const [GenreBooks, setGenreBooks] = useState([]);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [items, setItems] = useState([]);
    const [genreList, setGenreList] = useState();

    const [loadMoreBtn, setLoadMoreBtn] = useState(false);


    const [priceSort, setPriceSort] = useState(1);

    async function LoadGenres() {


        const url = '/api/genresList?id=1';
        const response = await fetch(url);
        const data = await response.json();
        setGenreList(data);
        setLoading(false)
        setGenreId(0)


    }


    function loadMore() {


        setItems(items.concat(GenreBooks.results.slice(items.length, items.length + 30)))

        if (items.length == GenreBooks.results.length) {
            setLoadMoreBtn(false);
        }



    }



    const onSubmit = data => {
        setFilterUse(true)
        LoadDataFilter(data);



    }

    async function LoadDataFilter(_data) {
        setItems([])
        setLoadMoreBtn(false)

        setFilterloading(true)
        let url = '/api/GetGenreBooks?id=' + genreId;


        if (_data._isPartner != null) {
            url = url + '&isPartner=' + _data._isPartner;
            setIsParner(isPartner);
        }
        if (_data._isSales != null) {
            if (_data._isSales == true) {
                url = url + '&isSales=' + 1;
                setIsSales(true)
            }
            else {
                url = url + '&isSales=' + 0;
                setIsSales(false)
            }

        }

        if (_data._priceFrom != null && _data._priceTo != null) {
            url = url + '&PriceFrom=' + _data._priceFrom + '&PriceTo=' + _data._priceTo;
            setPriceFrom(_data._priceFrom);
            setPriceTo(_data._priceTo);
        }
        else {
            url = url + '&PriceFrom=0' + '&PriceTo=99999';


        }
        if (LangId != null && LangId != "0") {
            url = url + '&LangId=' + LangId;

        }

        
        const response = await fetch(url);
        const datares = await response.json();

        if (datares.results.length > 0) {


            let byPrice = datares.results.slice(0);
            if (priceSort == 1) {
                byPrice.sort(function (a, b) {
                    return a.price - b.price;
                });
            }
            if (priceSort == 2) {
                byPrice.sort(function (a, b) {
                    return b.price - a.price;
                });
            }


            let datasorted = {
                nextUrl: null,
                previousUrl: null,
                type: "res",
                results: byPrice
            }

            setGenreBooks(datasorted)
            if (datasorted.results.length > 0) {
                setItems(datasorted.results.slice(0, 30))

                if (datasorted.results.length>30)
                    setLoadMoreBtn(true);

            } else {
                setItems()
                setLoadMoreBtn(false);
            }
       
            setFilterloading(false)



        }

        else {

            
            setGenreBooks(datares)
            setFilterloading(false)
        }

    }



    useEffect(() => {

        document.title = `Татарское книжное издательство`;


        if (loading === true) {
            LoadGenres();

            window.scrollTo(0, 0);


        }
    })


        return (
            <div>
                <Helmet>
                    <title>Татарское книжное издательство - онлайн магазин книг на татарском и русском языке</title>
                    <meta name="description" content="Онлайн магазин книг на татарском и русском языке. Купить художественные книги, учебники на татарском и русском языке" />
                </Helmet>

                
                <div className="content-wrap">
                    <div className="sidebar">


                        <div className="sidebar-section search-filter">
                            <h2>Фильтр</h2>
                            <div className="sidebar-section-content search-filter-sidebar">
                                <form className="filter-menu" onSubmit={handleSubmit(onSubmit)}>


                                    <div className="form-group">
                                        <label htmlFor="filter_price">Выберите категорию  </label>
                                        <select className="form-control" onChange={(e) => setGenreId(e.target.value)} name="genreId" value={genreId} ref={register({ required: true })} >
                                            <option  value="0" label="Все">Все</option>
                                            {genreList ? (genreList.map(genreListdata =>
                                                <option key={genreListdata.genresViewId} value={genreListdata.genresViewId}>{genreListdata.nameRus}</option>
                                            )
                                            ) : (<option>Загрузка...</option>)

                                            }
                                        </select>
                                    </div>




                                    <div className="form-group">
                                        <label htmlFor="filter_price">Язык издания</label>
                                        <select className="form-control" name="_langId" id="_langId" value={LangId} onChange={e => setLangId(e.target.value)} ref={register}>
                                            <option value="0" label="Все">Все</option>
                                            <option value="1" label="татарский"> татарский</option>
                                            <option value="2" label="русский"> русский</option>
                                            <option value="3" label="русский/татарский"> русский/татарский</option>
                                            <option value="4" label="татарский/русский/английский">татарский/русский/английский</option>
                                            <option value="5" label="английский">английский</option>
                                            <option value="6" label="удмуртский/русский/татарский/английский">удмуртский/русский/татарский/английский</option>
                                           
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="filter_price">Сортировка по цене </label>
                                        <select className="form-control" name="_langId" id="_PriceSort" value={priceSort} onChange={e => setPriceSort(e.target.value)} ref={register}>

                                            <option value="1" label="По возрастанию">По возрастанию</option>
                                            <option value="2" label="По убыванию">По убыванию</option>


                                        </select>
                                    </div>



                                    <div className="form-group">
                                        <label htmlFor="filter_availability">Парнтерские книги</label>
                                        <input type="checkbox" style={{ float: "left" }} name="_isPartner" ref={register} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="filter_search_lang">Акции</label>
                                        <input type="checkbox" style={{ float: "left" }} name="_isSales" ref={register} />
                                    </div>



                                    <div className="form-group padded-btn-wrap">
                                        <button type="submit" className="btn btn-primary btn-my-search">
                                            Поиск</button>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <BookSidebar />


                    </div>




                 

                        {filterUse === false &&
                            
                            <div className="main-content no-borders home-page">
                                <div className="block-wrap padding-zero">

                                    <div className="block">
                                        <StoreCatList />

                                    </div>
                                </div>
                                <div className="content-block">
                                    <div className="block-wrap padding-zero ">
                                        <div className="block-header">
                                            <h2 className="titleHead">Детская литература</h2>
                                        </div>
                                        <div className="block ">
                                            <StoreChild />

                                        </div>
                                    </div>
                                </div>
                                <div className="content-block">
                                    <div className="block-wrap  ">
                                        <div className="block-header">
                                            <h2 className="titleHead">Поэзия</h2>
                                        </div>
                                        <div className="block ">
                                            <StorePoetry />

                                        </div>
                                    </div>
                                </div>

                                <div className="content-block">
                                    <div className="block-wrap  ">

                                        <div className="block-header">
                                            <h2 className="titleHead">Публицистика</h2>
                                        </div>
                                        <div className="block ">
                                            <StorePub />

                                        </div>
                                    </div>

                                </div>
                            
                        </div>
                        }
                        {filterUse === true &&

                            <>
                        <div className="main-content  category-page">
                            <div className="content-block">
                                <div className="block-wrap">
                                    <div className="block">
                                        <div className="tab-wrap module type-book grid">

                                            <div className="tab search">
                                                {filterloading === true &&
                                                    <>
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                        <BookItemLoader />
                                                    </>


                                                }
                                                {filterloading === false &&
                                                    <>

                                                    {items ? (

                                                            <>
                                                            {items.length > 0 &&
                                                                    <>

                                                                {items.map((results, idx) =>

                                                                            <BookItems key={idx} id={results.id} owl="none" results={results} />


                                                                        )

                                                                        }
                                                                    </>
                                                                }

                                                            {items.length === 0 &&



                                                                    <div className="no-book"><p>Извините. На данный момент нет  доступных книг по Вашему запросу</p></div>
                                                                }

                                                            </>
                                                        )
                                                            : (<div className="no-book"><p>Извините. На данный момент нет  доступных книг по Вашему запросу</p></div>)
                                                        }

                                                    </>
                                                }







                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loadMoreBtn === true &&
                                <div className="foot-block">
                                    <div className="text-center">




                                        <div onClick={loadMore} className="btnP btn">ЕЩЕ</div>


                                    </div>
                                </div>
                            }



                        </div>
                       




                            </>
                        }
                  
                        
                  

                </div>

            </div>

                    
            
        );
    
}
export default Store;