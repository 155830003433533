import React from 'react';
import './account.css';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'




const Account = () => {
        return (


            <div className="container">

                <div className="row">
                    

                    <div className="ps-block--myaccount ">
                        <i className="fas fa-cart-arrow-down"></i>
                        <Nav.Link as={Link} to="/myorders/" className="ps-block__overlay" >
                            
                            <p>Мои заказы</p>
                        </Nav.Link>
                    </div>

                    <div className="ps-block--myaccount ">
                        <i className="fas fa-book"></i>
                        <Nav.Link as={Link} to="/mylibrary" className="ps-block__overlay" >
                            
                            <p>Мои электронные книги</p>
                            
                        </Nav.Link>
                    </div>

        

                    
                </div>


            </div>


     )
 }
                                
export default Account;