import React from 'react';


import SideBarList from '../../components/Sidebar/SideBarList';

import { LazyLoadImage } from 'react-lazy-load-image-component';


function PrivacyAr() {

    return (

 

            <div className="main-content  category-page">







                <div className="content-block padding-zero">
                    <div className="block-wrap">
                        <div className="block">

                            <div className="tab-wrap module type-book grid">

                                <div className="col-xs-12 appstore ">




                                    <h1> Политика обработки и защиты персональных данных</h1>
                                    <div class="row">
                                        <div className="col-xs-12 appstore ">

                                         
                                   
                                            <ol>
                                                <li><strong><em> Общие положения</em></strong></li>
                                            </ol>
                                            <p>1.1. Настоящая политика (далее &ndash; Политика) в области обработки и защиты персональных данных Государственном унитарное предприятии Республики Татарстан &laquo;Татарское книжное издательство&raquo; (далее - Оператор) разработана в целях обеспечения реализации требований законодательства РФ в области обработки персональных данных субъектов персональных данных.</p>
                                            <p>1.2. Настоящая Политика раскрывает основные категории персональных данных, обрабатываемых Оператором, цели, способы и принципы обработки Оператором персональных данных, права и обязанности Оператора при обработке персональных данных, права субъектов персональных данных, а также перечень мер, применяемых Оператором в целях обеспечения безопасности персональных данных при их обработке.</p>
                                            <p>1.3. Политика Оператора в области обработки персональных данных определяется в соответствии со следующими нормативными правовыми актами РФ:</p>
                                            <p>- Конституция Российской Федерации;</p>
                                            <p>- Трудовой кодекс Российской Федерации;</p>
                                            <p>- Федеральный закон РФ от 01 апреля 1996 года № 27-ФЗ &laquo;Об индивидуальном (персонифицированном) учете и системе обязательного пенсионного страхования&raquo;;</p>
                                            <p>- Федеральный закон РФ от 27 июля 2006 года № 152-ФЗ &laquo;О персональных данных&raquo;;</p>
                                            <p>- Федеральный закон от 29 декабря 2006 года № 255-ФЗ &laquo;Об обязательном социальном страховании на случай временной нетрудоспособности и в связи с материнством&raquo;;</p>
                                            <p>- Федеральный закон от 29 ноября 2010 года № 326-ФЗ &laquo;Об обязательном медицинском страховании в Российской Федерации&raquo;;</p>
                                            <p>- Постановление Правительства Российской Федерации от 01 ноября 2012 года № 1119 &laquo;Об утверждении требований к защите персональных данных при обработке в информационных системах персональных данных&raquo;;</p>
                                            <p>- Положение об обработке персональных данных, осуществляемых без использования средств автоматизации, утверждённое постановлением Правительства Российской Федерации от 15 сентября 2008 года № 687.</p>
                                            <p>1.4. Настоящая Политика является общедоступным документом, декларирующим концептуальные основы деятельности Оператора при обработке персональных данных.</p>
                                            <p>&nbsp;</p>
                                            <ol start="2">
                                                <li><strong><em> Термины и определения</em></strong></li>
                                            </ol>
                                            <p>Автоматизированная обработка персональных данных &mdash; обработка персональных данных с помощью средств вычислительной техники;</p>
                                            <p>Биометрические персональные данные &mdash; сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность;</p>
                                            <p>Блокирование персональных данных &mdash; временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
                                            <p>Обезличивание персональных данных &mdash; действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному субъекту персональных данных;</p>
                                            <p>Обработка персональных данных &mdash; любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
                                            <p>Персональные данные &ndash; любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);</p>
                                            <p>Предоставление персональных данных &ndash; действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
                                            <p>Распространение персональных данных &mdash; действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
                                            <p>Трансграничная передача персональных данных &mdash; передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</p>
                                            <p>Уничтожение персональных данных &mdash; действия, в результате которых невозможно восстановить содержание персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.</p>
                                            <p>&nbsp;</p>
                                            <ol start="3">
                                                <li><strong><em> Цели и принципы обработки персональных данных</em></strong></li>
                                            </ol>
                                            <p>3.1. Персональные данные обрабатываются Оператором в следующих целях:</p>
                                            <p>3.1.1. осуществление и выполнение возложенных законодательством Российской Федерации на Оператора функций, полномочий и обязанностей, в частности:</p>
                                            <p>&mdash;по предоставлению персональных данных в органы государственной власти, в Пенсионный фонд Российской Федерации, в Фонд социального страхования Российской Федерации, в Федеральный фонд обязательного медицинского страхования, а также в иные государственные органы и органы местного самоуправления;</p>
                                            <p>&mdash; ведение текущего бухгалтерского и налогового учёта, формирование, изготовление и своевременная подача бухгалтерской, налоговой и статистической отчётности;</p>
                                            <p>&mdash; исполнения судебных актов, актов других органов или должностных лиц, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;</p>
                                            <p>&mdash; регулирования трудовых отношений с работниками Оператора (содействие в трудоустройстве, обучение и продвижение по службе, обеспечение личной безопасности, контроль количества и качества выполняемой работы, обеспечение сохранности имущества);</p>
                                            <p>&mdash; предоставления работникам Оператора и членам их семей дополнительных гарантий и компенсаций, в том числе негосударственного пенсионного обеспечения, добровольного медицинского страхования, медицинского обслуживания и других видов социального обеспечения;</p>
                                            <p>&mdash; выполнение требований законодательства по определению порядка обработки и защиты персональных данных граждан, являющихся контрагентами Оператора.</p>
                                            <p>3.1.2. осуществления прав и законных интересов Оператора в рамках осуществления видов деятельности, предусмотренных Уставом и иными локальными нормативными актами Оператора, или третьих лиц либо достижения общественно значимых целей;</p>
                                            <p>3.1.3. в иных законных целях.</p>
                                            <p>3.2. Обработка персональных данных Оператором осуществляется на основе следующих принципов:</p>
                                            <p>3.2.1. обработка персональных данных осуществляется на законной и справедливой основе;</p>
                                            <p>3.2.2. обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей;</p>
                                            <p>3.2.3. не допускается обработка персональных данных, несовместимая с целями сбора персональных данных;</p>
                                            <p>3.2.4. не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</p>
                                            <p>3.2.5. обработке подлежат только персональные данные, которые отвечают целям их обработки;</p>
                                            <p>3.2.6. содержание и объем обрабатываемых персональных данных соответствует заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки;</p>
                                            <p>3.2.7. при обработке персональных данных обеспечиваются точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных;</p>
                                            <p>3.2.8. хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем того требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных;</p>
                                            <p>3.2.9. обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</p>
                                            <p>&nbsp;</p>
                                            <ol start="4">
                                                <li><strong><em> Действия, осуществляемые с персональными данными,</em></strong></li>
                                            </ol>
                                            <p><strong><em>и способы их осуществления</em></strong></p>
                                            <p>4.1. Оператор при обработке персональных данных осуществляет следующие действия:</p>
                                            <ul>
                                                <li>сбор,</li>
                                                <li>запись,</li>
                                                <li>систематизация,</li>
                                                <li>накопление,</li>
                                                <li>хранение,</li>
                                                <li>уточнение (обновление, изменение),</li>
                                                <li>извлечение,</li>
                                                <li>использование,</li>
                                                <li>передача (распространение, предоставление, доступ),</li>
                                                <li>обезличивание,</li>
                                                <li>блокирование,</li>
                                                <li>удаление, уничтожение персональных данных.</li>
                                            </ul>
                                            <p>4.2. Обработка персональных данных Оператором осуществляется следующими способами:</p>
                                            <ul>
                                                <li>неавтоматизированная обработка персональных данных;</li>
                                                <li>автоматизированная обработка персональных данных с передачей полученной информации по информационно-телекоммуникационным сетям или без таковой;</li>
                                                <li>смешанная обработка персональных данных.</li>
                                            </ul>
                                            <p>&nbsp;</p>
                                            <ol start="5">
                                                <li><strong><em> Категории субъектов персональных данных и их права</em></strong></li>
                                            </ol>
                                            <p>5.1. Оператор осуществляет обработку персональных данных следующих категорий субъектов:</p>
                                            <p>5.1.1. работники Оператора, а также родственники Работника;</p>
                                            <p>5.1.2. контрагенты Оператора.</p>
                                            <p>5.2. Субъект персональных данных имеет право на получение сведений об обработке его персональных данных Оператором.</p>
                                            <p>5.3. Субъект персональных данных вправе требовать от Оператора уточнения, обрабатываемых Оператором персональных данных субъекта персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
                                            <p>5.4. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.</p>
                                            <p>5.5. Для реализации своих прав и защиты законных интересов субъект персональных данных имеет право обратиться к Оператору. Оператор рассматривает любые обращения и жалобы со стороны субъектов персональных данных, тщательно расследует факты нарушений и принимает все необходимые меры для их немедленного устранения, наказания виновных лиц и урегулирования спорных и конфликтных ситуаций в досудебном порядке.</p>
                                            <p>5.6. Субъект персональных данных вправе обжаловать действия или бездействие Оператора путем обращения в уполномоченный орган по защите прав субъектов персональных данных.</p>
                                            <p>5.7. Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.</p>
                                            <p>&nbsp;</p>
                                            <ol start="6">
                                                <li><strong><em> Категории персональных данных и источники их получения</em></strong></li>
                                            </ol>
                                            <p>6.1. Оператором обрабатываются следующие категории персональных данных:</p>
                                            <p>6.1.1. Персональные данные работников Оператора, в том числе родственников работника:</p>
                                            <ul>
                                                <li>Фамилия, имя, отчество;</li>
                                                <li>Паспортные данные;</li>
                                                <li>Дата и место рождения;</li>
                                                <li>Адрес проживания;</li>
                                                <li>Семейное положение;</li>
                                                <li>Образование;</li>
                                                <li>Профессия;</li>
                                                <li>Данные ИНН;</li>
                                                <li>Данные Пенсионного страхового свидетельства;</li>
                                                <li>Данные медицинских полисов;</li>
                                                <li>Сведения о рождении детей, о заключении/расторжении брака;</li>
                                                <li>Данные о воинском учете;</li>
                                                <li>Место работы;</li>
                                                <li>Должность;</li>
                                                <li>Телефоны домашний и сотовый;</li>
                                                <li>Сведения о трудовой деятельности.</li>
                                                <li>Сведения о родственниках работника (фамилия, имя, отчество, дата рождения; степень родства).</li>
                                            </ul>
                                            <p>6.1.2. Персональные данные физических лиц &ndash; контрагентов Оператора:</p>
                                            <ul>
                                                <li>Фамилия, имя, отчество;</li>
                                                <li>Дата и место рождения;</li>
                                                <li>Адрес проживания;</li>
                                                <li>Паспортные данные;</li>
                                                <li>Данные ИНН;</li>
                                                <li>Данные Пенсионного страхового свидетельства;</li>
                                                <li>Телефоны домашний и сотовый;</li>
                                                <li>Адрес электронной почты;</li>
                                                <li>сведения о предпочтениях при выборе продукции Издательства и о количестве приобретаемой продукции;</li>
                                                <li>сведения о страницах в социальных сетях;</li>
                                                <li>пользовательские данные (сведения о местоположении;о действиях на сайте Издательства; IP-адрес; SID);</li>
                                                <li>сведения о поступающих платежах;</li>
                                                <li>Реквизиты банковского счета.</li>
                                            </ul>
                                            <p>6.2. Получение сведений о персональных данных осуществляется на основании документов и информации, представленных лично работниками Оператора в процессе трудовых отношений, а также лично лицами, заключающими гражданско-правовые договоры с Оператором, граждан, обратившихся к Оператору в установленном порядке.</p>
                                            <p>&nbsp;</p>
                                            <ol start="7">
                                                <li><strong><em> Порядок обработки персональных данных</em></strong></li>
                                            </ol>
                                            <p>7.1. Оператор производит обработку персональных данных при наличии хотя бы одного из следующих условий:</p>
                                            <p>7.1.1. обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;</p>
                                            <p>7.1.2. обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей;</p>
                                            <p>7.1.3. обработка персональных данных осуществляется в связи с участием лица в конституционном, гражданском, административном, уголовном судопроизводстве, судопроизводстве в арбитражных судах;</p>
                                            <p>7.1.4.&nbsp; обработка персональных данных необходима для исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;</p>
                                            <p>7.1.5. обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;</p>
                                            <p>7.1.6. обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;</p>
                                            <p>7.1.7. обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц, либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;</p>
                                            <p>7.1.8. обработка персональных данных необходима для осуществления профессиональной деятельности журналиста и (или) законной деятельности средства массовой информации либо научной, литературной или иной творческой деятельности при условии, что при этом не нарушаются права и законные интересы субъекта персональных данных;</p>
                                            <p>7.1.9. обработка персональных данных осуществляется в статистических или иных исследовательских целях, при условии обязательного обезличивания персональных данных;</p>
                                            <p>7.1.10. осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе;</p>
                                            <p>7.1.11. осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.</p>
                                            <p>7.2. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни, Оператором не осуществляется.</p>
                                            <p>Обработка персональных данных о судимости может осуществляться Оператором исключительно в случаях и в порядке, которые определяются в соответствии с федеральными законами.</p>
                                            <p>7.3. Обработка биометрических персональных данных Оператором не осуществляется.</p>
                                            <p>7.4. Трансграничная передача персональных данных Оператором не осуществляется.</p>
                                            <p>7.5. Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом. Лицо, осуществляющее обработку персональных данных по поручению оператора, обязано соблюдать принципы и правила обработки персональных данных, предусмотренные действующим законодательством и настоящей Политикой.</p>
                                            <p>Лицо, осуществляющее обработку персональных данных по поручению оператора, не обязано получать согласие субъекта персональных данных на обработку его персональных данных.</p>
                                            <p>В случае, если оператор поручает обработку персональных данных другому лицу, ответственность перед субъектом персональных данных за действия указанного лица несет оператор. Лицо, осуществляющее обработку персональных данных по поручению оператора, несет ответственность перед оператором.</p>
                                            <p>&nbsp;</p>
                                            <ol start="8">
                                                <li><strong><em> Доступ к персональным данным</em></strong></li>
                                            </ol>
                                            <p>8.1. Право доступа к персональным данным сотрудников имеют:</p>
                                            <p>- генеральный директор Оператора;</p>
                                            <p>- сотрудники отдела кадров;</p>
                                            <p>- сотрудники бухгалтерии;</p>
                                            <p>- юрисконсульт;</p>
                                            <p>- заведующий канцелярией;</p>
                                            <p>- специалист по воинскому учету;</p>
                                            <p>- сотрудники, ответственные за оформление договоров с физическими лицами &ndash; контрагентами Оператора;</p>
                                            <p>- руководители структурных подразделений по направлению деятельности.</p>
                                            <p>Указанные лица проходят ознакомление с требованиями федерального законодательства и нормативных документов Оператора по обработке и защите персональных данных, а также дают обязательство по неразглашению сведений, составляющих персональные данные.</p>
                                            <p>8.2. Доступ к персональным данным иным лицам может быть предоставлен на основании приказа Генерального директора Оператора в целях, установленных пунктом 3.1 настоящей Политики, с обязательным ознакомлением указанных лиц с требованиями федерального законодательства и нормативных документов Оператора по обработке и защите персональных данных.</p>
                                            <p>Лицо, которому предоставлен доступ к персональным данным в порядке, установленном настоящим пунктам, принимает на себя обязательства по неразглашению указанных сведений.</p>
                                            <p>8.3. При передаче персональных данных сотрудника работодатель должен соблюдать следующие требования:</p>
                                            <p>8.3.1. Не сообщать персональные данные сотрудника третьей стороне без письменного согласия сотрудника, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни и здоровью сотрудника, а также в случаях, установленных федеральным законом;</p>
                                            <p>8.3.2. Не сообщать персональные данные сотрудника в коммерческих целях без его письменного согласия. Обработка персональных данных сотрудников в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи допускается только с его предварительного согласия;</p>
                                            <p>8.3.3. Предупредить лиц, получивших персональные данные сотрудника, о том, что эти данные могут быть использованы лишь в целях, для которых они сообщены, и требовать от этих лиц подтверждение того, что это правило соблюдено;</p>
                                            <p>8.3.4. Не запрашивать информацию о состоянии здоровья сотрудника, за исключением тех сведений, которые относятся к вопросу о возможности выполнения сотрудником трудовой функции;</p>
                                            <p>8.3.5. Передавать персональные данные сотрудника его законным, полномочным представителям в порядке, установленном Трудовым кодексом РФ, и ограничивать эту информацию только теми персональными данными, которые необходимы для выполнения указанными представителями их функции.</p>
                                            <p>&nbsp;</p>
                                            <ol start="9">
                                                <li><strong><em> Меры по защите персональных данных</em></strong></li>
                                            </ol>
                                            <p>9.1. Безопасность персональных данных, обрабатываемых Оператором, обеспечивается реализацией правовых, организационных и технических мер, необходимых для обеспечения требований федерального законодательства в области защиты персональных данных.</p>
                                            <p>9.2. Для предотвращения несанкционированного доступа к персональным данным Оператором применяются следующие организационно-технические меры:</p>
                                            <p>9.2.1. принятие локальных нормативных актов и иных документов в области обработки и защиты персональных данных;</p>
                                            <p>9.2.2. назначение должностных лиц, ответственных за организацию обработки и защиты персональных данных;</p>
                                            <p>9.2.3. ограничение состава лиц, допущенных к обработке персональных данных;</p>
                                            <p>9.2.4. организация обучения и проведение методической работы с лицами, осуществляющими обработку персональных данных;</p>
                                            <p>9.2.5. ознакомление субъектов с требованиями федерального законодательства и нормативных документов Оператора по обработке и защите персональных данных;</p>
                                            <p>9.2.6. получение согласий субъектов персональных данных на обработку их персональных данных, за исключением случаев, предусмотренных законодательством Российской Федерации;</p>
                                            <p>9.2.7. организация учета, хранения и обращения носителей, содержащих информацию с персональными данными;</p>
                                            <p>9.2.8. определение угроз безопасности персональных данных при их обработке, формирование на их основе моделей угроз;</p>
                                            <p>9.2.9. использование средств защиты информации, прошедших процедуру оценки соответствия требованиям законодательства Российской Федерации в области обеспечения безопасности информации, в случае, когда применение таких средств необходимо для нейтрализации актуальных угроз;</p>
                                            <p>9.2.10. использование антивирусных средств и средств восстановления системы защиты персональных данных;</p>
                                            <p>9.2.11. применение в необходимых случаях средств межсетевого экранирования, обнаружения вторжений, анализа защищенности и средств криптографической защиты информации;</p>
                                            <p>9.2.12. организация пропускного режима на территорию Оператора, охраны помещений с техническими средствами обработки персональных данных, с носителями, содержащими персональные данные.</p>
                                            <p>&nbsp;</p>
                                            <ol start="10">
                                                <li><strong><em> Срок хранения персональных данных, </em></strong></li>
                                            </ol>
                                            <p><strong><em>блокировка и уничтожение персональных данных</em></strong></p>
                                            <p>10.1. Течение срока обработки персональных данных начинается с момента их получения Оператором.</p>
                                            <p>10.2. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъект персональных данных, не дольше, чем того требуют цели их обработки.</p>
                                            <p>10.3. Персональные данные работников Оператора, в том числе родственников работника, используются в течение трудовой деятельности в соответствии с трудовым договором, а также на протяжении установленного законодательством срока хранения личного дела в архиве (75 лет).</p>
                                            <p>10.4. Персональные данные граждан &ndash; контрагентов Оператора хранятся в течение срока, определённого действующим законодательством.</p>
                                            <p>10.5. Целью блокирования персональных данных является временное прекращение обработки персональных данных до момента устранения обстоятельств, послуживших основанием для блокирования персональных данных.</p>
                                            <p>Блокирование персональных данных осуществляется Оператором по требованию субъекта персональных данных или его представителя, а также по требованию уполномоченного органа по защите прав субъектов персональных данных в случае выявления недостоверных персональных данных или неправомерных действий с ними.</p>
                                            <p>10.6. Уничтожение персональных данных осуществляется Оператором:</p>
                                            <p>- по достижении цели обработки персональных данных;</p>
                                            <p>- в случае утраты необходимости в достижении целей обработки персональных данных;</p>
                                            <p>- в случае отзыва субъектом персональных данных согласия на обработку своих персональных данных;</p>
                                            <p>- по требованию субъекта персональных данных или уполномоченного органа по защите прав субъектов персональных данных в случае выявления фактов совершения администрацией района неправомерных действий с персональными данными, когда устранить соответствующие нарушения не представляется возможным.</p>
                                            <p>При уничтожении материальных носителей персональных данных составляется акт об уничтожении носителей, содержащих персональные данные.</p>
                                            <p>&nbsp;</p>
                                            <ol start="11">
                                                <li><strong><em> Ответственность</em></strong></li>
                                            </ol>
                                            <p>11.1. Работники Оператора, виновные в нарушении норм, регулирующих обработку и защиту персональных данных, несут материальную, дисциплинарную, административную, гражданско-правовую или уголовную ответственность в порядке, установленном федеральными законами.</p></div>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



    );

}


export default PrivacyAr;


