import React from "react"
import ContentLoader from "react-content-loader"

const BookItemLoader = (props) => (
    <ContentLoader
        speed={2}
        width={160}
        height={350}
        viewBox="0 0 160 350"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="10" y="251" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="299" rx="2" ry="2" width="140" height="33" />
        <rect x="6" y="3" rx="2" ry="2" width="147" height="225" />
        <rect x="11" y="275" rx="2" ry="2" width="140" height="10" />
    </ContentLoader>
)

export default BookItemLoader;




