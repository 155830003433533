import Cookies from 'universal-cookie';
const Storage = (cartItems) => {
    if (cartItems != "null" && cartItems != [] && cartItems != null)
        SetCart(cartItems);
}


async function SetCart(cartItems) {
    const cookies = new Cookies();
    
    const cuid = cookies.get('cartuid');
  

    let datas = {
        "data": JSON.stringify(cartItems),
        "sessionId": cuid,
    }

    const headersReq = new Headers();
    localStorage.setItem('cart', JSON.stringify(cartItems.length > 0 ? cartItems : []));
    if (JSON.parse(localStorage.getItem('cart')) != JSON.stringify(cartItems))
    {
        
        headersReq.append('Content-Type', 'application/json');
        const url = '/api/CartBook';
        const res = await
            fetch(url, {
                method: 'post',
                headers: headersReq,
                body: JSON.stringify(datas)
            }).then(response => {
                if (response.status == 200) {
                   
                    return response;
                }
                if (response.status == 401) {


                }
            });
   

        const p = await res.json();
      
    }

  


}



export const sumItemsUpdate = cartItems => {
    
    let itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);

    let priceopt = cartItems.reduce((total, product) => total + product.priceOpt * product.quantity, 0).toFixed(2);
    
    let total = cartItems.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    if (priceopt > 10000) {
        total = priceopt;
    }
    return { itemCount, total }
}



export const sumItems = cartItems => {
    Storage(cartItems);
    let itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
    
    let total = cartItems.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    return { itemCount, total }
}




export const CartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            if (!state.cartItems.find(item => item.id === action.payload.id)) {
                state.cartItems.push({
                    ...action.payload,
                    quantity: 1
                })
            }


            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }

        case "RESET":
            

            return {
                ...sumItemsUpdate(action.payload),
                checkout: false,
                cartItems: action.payload
            }
            
        case "REMOVE_ITEM":
            return {
                ...state,
                ...sumItems(state.cartItems.filter(item => item.id !== action.payload.id)),
                cartItems: [...state.cartItems.filter(item => item.id !== action.payload.id)]
            }
        case "INCREASE":
            state.cartItems[state.cartItems.findIndex(item => item.id === action.payload.id)].quantity++
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "DECREASE":
            state.cartItems[state.cartItems.findIndex(item => item.id === action.payload.id)].quantity--
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "CHECKOUT":
            return {
                cartItems: [],
                checkout: true,
                ...sumItems([]),
            }
        case "CLEAR":
            return {
                cartItems: [],
                ...sumItems([]),
            }
        default:
            return state

    }
}