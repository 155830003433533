
import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ContentLoader from "react-content-loader"
class TopAuthors extends React.Component {
    

    constructor(props) {
        super(props);
        this.state = { Authors: [], loading: true };
    }

    componentDidMount() {
        this.authorsData();
    }



    static topAuthorsRender(Authors) {
        return (
            <div className="block-wrap sidebar ">
                <div className="block-header">
                    <h2>
                        <Link to="/authors/" className="sidebarTitle">Популярные авторы</Link>
                    </h2>
                </div>
                <div className="block">
                    <div className="tab-wrap module type-link grid tab-6252 tab-active">

                        <div className="tab show-all">
                            {Authors.results.map(results =>
                                <Link to={results.url} key={results.id} className="link-item-text-only link-item">
                                    <div className="link-text"><font ><font >{results.name}</font>
                                    </font>
                                    </div>
                                </Link>
                               
                             
                            )}
                            
                        </div>
                    </div>

                </div>
            </div>
        );
    };



    render() {
        let contents = this.state.loading
            ?

            <div className="block-wrap sidebar ">
                <div className="block-header">
            <ContentLoader
                speed={2}
                width={400}
                height={150}
                viewBox="0 0 400 150"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                
            >
                        <circle cx="10" cy="20" r="8" />
                        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
                        <circle cx="10" cy="50" r="8" />
                        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
                        <circle cx="10" cy="80" r="8" />
                        <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
                        <circle cx="10" cy="110" r="8" />
                        <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
                        <circle cx="10" cy="140" r="8" />
                        <rect x="25" y="135" rx="5" ry="5" width="220" height="10" />
                        <circle cx="10" cy="179" r="8" />
                        <rect x="25" y="174" rx="5" ry="5" width="220" height="10" />
                    </ContentLoader>

                </div>
            </div>
            : TopAuthors.topAuthorsRender(this.state.Authors);

        return (
            <div>
                               
                {contents}
            </div>
        );
    };



    async authorsData() {

        const response = await fetch('/api/topauthors');
        const data = await response.json();
        
       
        this.setState({ Authors: data, loading: false });
        
        
    };
};

export default TopAuthors;

