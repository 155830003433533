import React from 'react';


import BookSidebar from '../../components/Sidebar/BookSidebar';
import SideBarList from '../../components/Sidebar/SideBarList';
import { LazyLoadImage } from 'react-lazy-load-image-component';


function PrivacyAr() {

    return (

  

            <div className="main-content  category-page">







                <div className="content-block padding-zero">
                    <div className="block-wrap">
                        <div className="block">

                            <div className="tab-wrap module type-book grid">

                                <div className="col-xs-12 appstore ">


                                    
                                    
                                    <h1> Privacy Policy</h1>
                                    <div class="row">
                                        <div className="col-xs-12 appstore ">

                                        <p> TBPC built the TatKitap (ТатКитап) app as
                                        a Free app. This SERVICE is provided by
                                        Tatar Book Publishing Company at no cost and is intended for
                                        use as is.
</p> <p>
                                            This page is used to inform visitors regarding
                                            our policies with the collection, use, and
                                            disclosure of Personal Information if anyone decided to use
                                            our Service.
</p> <p>
                                            If you choose to use our Service, then you agree
                                            to the collection and use of information in relation to this
                                            policy. The Personal Information that we collect is
                                            used for providing and improving the Service.
                                            We will not use or share your
                                            information with anyone except as described in this Privacy
                                            Policy.
</p> <p>
                                            The terms used in this Privacy Policy have the same meanings
                                            as in our Terms and Conditions, which is accessible at
                                            TatKitap (ТатКитап) unless otherwise defined in this Privacy
                                            Policy.
</p> <p><strong>Information Collection and Use</strong></p> <p>
                                            For a better experience, while using our Service,
                                            we may require you to provide us personally information about Location needed for AR functionality of the application. The
                                            information that we request will be
                                            retained by us and used as described in this privacy policy.
</p> <p>
                                            We want to inform you that whenever
                                            you use our Service, in a case of an error in the
                                            app we collect data and information (through third
                                            party products) on your phone called Log Data. This Log Data
                                            may include information such as your device Internet
                                            Protocol (“IP”) address, device name, operating system
                                            version, the configuration of the app when utilizing
                                            our Service, the time and date of your use of the
                                            Service, and other statistics.
</p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
                                            We may update our Privacy Policy from
                                            time to time. Thus, you are advised to review this page
                                            periodically for any changes. We will
                                            notify you of any changes by posting the new Privacy Policy
                                            on this page. These changes are effective immediately after
                                            they are posted on this page.
</p> <p><strong>Contact Us</strong></p> <p>
                                            If you have any questions or suggestions about
                                            our Privacy Policy, do not hesitate to contact
                                            us at 8 (843) 519-45-22; tki@tatkniga.ru.
                                            </p> </div>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


    );

}


export default PrivacyAr;


