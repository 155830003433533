import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';

class Brcrumb extends React.Component {


    render() {
        return (
            <ol className="breadcrumb">
                <li className="title">Категории:</li>
                <li>
                    
                    {this.props.genreslist.genreViewInBookModel.map((genreViewInBookModel,idx) =>
                        <span key={idx}>
                        <Link to={genreViewInBookModel.genresUrl}>
                                {genreViewInBookModel.genreNameRus}   </Link>
                            </span>
                       
                    )}
                   
              
                </li>

            </ol>
        );
    }
}

export default Brcrumb;