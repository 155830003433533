import React from 'react';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItems from '../../components/BooksGrid/BookItems';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import SideBarList from '../../components/Sidebar/SideBarList';


class Author extends React.Component {

    constructor(props) {
        super(props);
        this.state = { AuthorBook: [], loading: true };

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.authorData();
       
       
    }
    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.authorData();
            
            window.scrollTo(0, 0);
        }
    }

   

 

    static renderAuthorBook(AuthorBook) {
        return (
        <>


                <div className="main-content  category-page">

                    <div className="block-wrap">

                        <div className="content-block">
                            <div className="block-wrap">
                                <div className="block-header">
                                    <h2 className="titleHead">Автор</h2>
                                </div>
                                <div className="block">
                                    <div className="tab-wrap module type-book grid">
                                        <div className="tab search">
                                            {AuthorBook.results.map((results,idx) =>

                                                  <BookItems key={idx} id={results.id} owl="none" results={results} />



                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>

                </div>
           </>


        )
    };



    render() {
        let contents = this.state.loading
            ? <p><em>...</em></p>
            : Author.renderAuthorBook(this.state.AuthorBook);

        return (
            <div>

                <p></p>
                {contents}
            </div>
        );
    }



    async authorData() {

        const id = this.props.match.params.id;
        
        

        const response = await fetch('/api/authors/'+id);

        const data = await response.json();
        

        console.log(data)
      
        this.setState({ AuthorBook: data, loading: false});


    }
}

export default Author;













